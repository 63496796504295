import React, { useState, useEffect } from 'react';
import { useState as useHookState } from '@hookstate/core';
import {
  Button,
  Checkbox,
  Container,
  Form,
  FormField,
  Input,
  Select,
  SpaceBetween
} from '@amzn/awsui-components-react';
import { System } from '../../API';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../../API";
import { updateSystemV1 as updateSystemMutation } from "../../graphql/mutations";
import { UpdatedSystemInterface } from './TablePanel';
import { CancelUpdateSystemInterface } from './TablePanel';
import { SelectOptionInterface } from '../DeviceLinks/MainContents';
import { forceAwakensBaseState } from 'src/stores/app';
import { AUTHENTICATION_MODES, UserActionNames } from 'src/constants/Constants';
import { createUserAction } from 'src/utils/UserActionsUtils';

export interface SystemEditPanelPropsInterface {
  cancelCallback: CancelUpdateSystemInterface;
  saveCallback: UpdatedSystemInterface;
  secondarySystemOptions: SelectOptionInterface[];
  selectedSystem: System;
}

export const SystemEditPanel = (props: SystemEditPanelPropsInterface) => {

  const [authenticationValue, setAuthenticationValue] = useState<string>(props.selectedSystem.auth_mode);
  const [idValue, setIdValue] = useState<string>(() => props.selectedSystem.id!);
  const [nameValue, setNameValue] = useState<string>(() => props.selectedSystem.name!);
  const [segmentNameValue, setSegmentNameValue] = useState<string>(() => props.selectedSystem.segment_name!);
  const [segmentLocationValue, setSegmentLocationValue] = useState<string>(() => props.selectedSystem.segment_location!);
  const [segmentSourceValue, setSegmentSourceValue] = useState<string>(() => props.selectedSystem.segment_source!);
  const [serverVPCEndpointValue, setServerVPCEndpointValue] = useState<string>(() => props.selectedSystem.srvr_vpc_endpoint!);
  const [serverCommandServicePathValue, setServerCommandServicePathValue] = useState<string>(() => props.selectedSystem.srvr_cmd_srvc_path!);
  const [serverCommandServicePortValue, setServerCommandServicePortValue] = useState<string>(() => props.selectedSystem.srvr_cmd_srvc_port!);
  const [serverConfigurationServicePathValue, setServerConfigurationServicePathValue] = useState<string>(() => props.selectedSystem.srvr_cnf_srvc_path!);
  const [serverConfigurationServicePortValue, setServerConfigurationServicePortValue] = useState<string>(() => props.selectedSystem.srvr_cnf_srvc_port!);
  const [serverAlarmServicePathValue, setServerAlarmServicePathValue] = useState<string>(() => props.selectedSystem.srvr_alrm_srvc_path!);
  const [serverAlarmServicePortValue, setServerAlarmServicePortValue] = useState<string>(() => props.selectedSystem.srvr_alrm_srvc_port!);
  const [enabledValue, setEnabledValue] = useState<string>(() => props.selectedSystem.enabled!);
  const [secondarySystemIdValue, setSecondarySystemIdValue] = useState<string>(() => props.selectedSystem.secondary_system_id || '0');
  const [secondarySystemNameValue, setSecondarySystemNameValue] = useState<string>(() => props.selectedSystem.secondary_system_name || 'None');

  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const forceAwakensState = useHookState(forceAwakensBaseState);

  useEffect(() => {
    if (initialLoad) {
      const systemNameInputComponent = document.getElementById("SystemNameInputField");
      const systemNameInputField = systemNameInputComponent?.firstChild as HTMLInputElement;
      systemNameInputField.focus();
      systemNameInputField.select();
      setInitialLoad(false);
    }
  });

  if (idValue !== props.selectedSystem.id) {
    setIdValue(props.selectedSystem.id!);
    setNameValue(props.selectedSystem.name!);
    setSegmentNameValue(props.selectedSystem.segment_name!);
    setSegmentLocationValue(props.selectedSystem.segment_location!);
    setSegmentSourceValue(props.selectedSystem.segment_source!);
  }

  const updateSystem = async () => {
    try {
      const response = await API.graphql(graphqlOperation(updateSystemMutation,
        {
          input: 
            {
              auth_mode: authenticationValue,
              enabled: enabledValue,
              id: idValue,
              name: nameValue,
              secondary_system_id: secondarySystemIdValue,
              segment_location: segmentLocationValue,
              segment_name: segmentNameValue,
              segment_source: segmentSourceValue,
              srvr_alrm_srvc_path: serverAlarmServicePathValue,
              srvr_alrm_srvc_port: serverAlarmServicePortValue,
              srvr_cnf_srvc_path: serverConfigurationServicePathValue,
              srvr_cnf_srvc_port: serverConfigurationServicePortValue,
              srvr_cmd_srvc_path: serverCommandServicePathValue,
              srvr_cmd_srvc_port: serverCommandServicePortValue,
              srvr_vpc_endpoint: serverVPCEndpointValue,
              updated_by: forceAwakensState.username.value,
            }
        })) as GraphQLResult<APIt.UpdateSystemV1Mutation>;
      if (response && response.data && response.data.updateSystemV1) {
        createUserAction(
          {
            actionName: UserActionNames.UpdateSystem,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                systemUpdate: response.data.updateSystemV1,
              })
          });
        const updatedSystem = response.data.updateSystemV1;
        setDisableUndo(true);
        props.saveCallback(updatedSystem);
      }
    } catch (e) {
      console.log(`updateSystem(): exception is ${JSON.stringify(e)}`);
      createUserAction(
        {
          actionName: UserActionNames.UpdateSystemError,
          username: forceAwakensState.username.value,
          parameters: JSON.stringify(
            {
              systemUpdateError: e,
            })
        });
    }
  };

  const cancelBtnHandler = () => {
    props.cancelCallback();
  };

  const saveBtnHandler = () => {
    updateSystem();
  };

  const undoBtnHandler = () => {
    setNameValue(props.selectedSystem.name);
    setSegmentNameValue(props.selectedSystem.segment_name);
    setSegmentLocationValue(props.selectedSystem.segment_location);
    setSegmentSourceValue(props.selectedSystem.segment_source);
    setEnabledValue(props.selectedSystem.enabled);
    setServerVPCEndpointValue(props.selectedSystem.srvr_vpc_endpoint);
    setServerCommandServicePathValue(props.selectedSystem.srvr_cmd_srvc_path);
    setServerCommandServicePortValue(props.selectedSystem.srvr_cmd_srvc_port);
    setServerConfigurationServicePathValue(props.selectedSystem.srvr_cnf_srvc_path);
    setServerConfigurationServicePortValue(props.selectedSystem.srvr_cnf_srvc_port);
    setServerAlarmServicePathValue(props.selectedSystem.srvr_alrm_srvc_path);
    setServerAlarmServicePortValue(props.selectedSystem.srvr_alrm_srvc_port);
    setSecondarySystemIdValue(props.selectedSystem.secondary_system_id || '');
    setSecondarySystemNameValue(props.selectedSystem.secondary_system_name || '');
    setDisableUndo(true);
  };

  const [disableUndo, setDisableUndo] = useState<boolean>(true);

  const authenticationFieldChangeHandler = (detail: any) => {
    setAuthenticationValue(detail.selectedOption.label);
  };

  const systemNameFieldOnChangeHandler = (detail: any) => {
    if (detail.value === props.selectedSystem.name && !disableUndo) setDisableUndo(true);
    if (detail.value !== props.selectedSystem.name && disableUndo) setDisableUndo(false);
    setNameValue((prevValue) => {return detail.value});
  };

  const segmentNameFieldOnChangeHandler = (detail: any) => {
    if (detail.value === props.selectedSystem.segment_name && !disableUndo) setDisableUndo(true);
    if (detail.value !== props.selectedSystem.segment_name && disableUndo) setDisableUndo(false);
    setSegmentNameValue(detail.value);
  };

  const secondarySystemFieldChangeHandler = (detail: any) => {
    setSecondarySystemNameValue(detail.selectedOption.label);
    setSecondarySystemIdValue(detail.selectedOption.value);
  };

  const systemEnabledFieldOnChangeHandler = (detail: any) => {
    detail.checked ? setEnabledValue('Y') : setEnabledValue('N');
  };

  return (
    <div key={props.selectedSystem.id}>
      <Container>
        <Form>
          <SpaceBetween size="xs" direction="vertical">
            <FormField description="A name for the system." label="Name">
              <Input
                id="SystemNameInputField"
                onChange={({ detail }) => systemNameFieldOnChangeHandler(detail)}
                value={nameValue}
                placeholder="Enter Name"
                inputMode="text"
                autoFocus
              />
            </FormField>
            <FormField description="Site Name." label="Site Name">
              <Input
                id="SegmentNameInputField"
                onChange={({ detail }) => segmentNameFieldOnChangeHandler(detail)}
                value={segmentNameValue}
                placeholder="Enter Site Name"
                inputMode="text"
                readOnly
              />
            </FormField>
            <FormField description="Server VPC Endpoint." label="Server VPC Endpoint">
              <Input
                id="ServerVPCEndpoint"
                onChange={({ detail }) => setServerVPCEndpointValue(detail.value)}
                value={serverVPCEndpointValue}
                placeholder="Enter VPC Endpoint"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Configuration Service Path." label="Server Configuration Service Path">
              <Input
                id="serverConfigurationServicePath"
                onChange={({ detail }) => setServerConfigurationServicePathValue(detail.value)}
                value={serverConfigurationServicePathValue}
                placeholder="Enter Server Configration Service Path"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Configuration Service Port." label="Server Configuration Service Port">
              <Input
                id="serverConfigurationServicePort"
                onChange={({ detail }) => setServerConfigurationServicePortValue(detail.value)}
                value={serverConfigurationServicePortValue}
                placeholder="Enter Server Configration Service Port"
                inputMode="numeric"
              />
            </FormField>
            <FormField description="Server Command Service Path." label="Server Command Service Path">
              <Input
                id="serverCommandServicePath"
                onChange={({ detail }) => setServerCommandServicePathValue(detail.value)}
                value={serverCommandServicePathValue}
                placeholder="Enter Server Command Service Path"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Command Service Port." label="Server Command Service Port">
              <Input
                id="serverCommandServicePort"
                onChange={({ detail }) => setServerCommandServicePortValue(detail.value)}
                value={serverCommandServicePortValue}
                placeholder="Enter Server Command Service Port"
                inputMode="numeric"
              />
            </FormField>
            <FormField description="Server Alarm Service Path." label="Server Alarm Service Path">
              <Input
                id="serverAlarmServicePath"
                onChange={({ detail }) => setServerAlarmServicePathValue(detail.value)}
                value={serverAlarmServicePathValue}
                placeholder="Enter Server Alarm Service Path"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Alarm Service Port." label="Server Alarm Service Port">
              <Input
                id="serverAlarmServicePort"
                onChange={({ detail }) => setServerAlarmServicePortValue(detail.value)}
                value={serverAlarmServicePortValue}
                placeholder="Enter Server Alarm Service Port"
                inputMode="numeric"
              />
            </FormField>
            <FormField description="Authentication" label="Authentication">
              <Select
                id="Authentication"
                filteringType='auto'
                onChange={({ detail }) => authenticationFieldChangeHandler(detail)}
                options={AUTHENTICATION_MODES}
                selectedAriaLabel="Selected"
                selectedOption={{ label: authenticationValue, value: authenticationValue }}
              />
            </FormField>
            <FormField description="Secondary System." label="Secondary System">
              <Select
                id="SecondarySystem"
                filteringType='auto'
                onChange={({ detail }) => secondarySystemFieldChangeHandler(detail)}
                options={props.secondarySystemOptions}
                selectedAriaLabel="Selected"
                selectedOption={{ label: secondarySystemNameValue, value: secondarySystemIdValue }}
              />
            </FormField>
            <FormField description="Enable or disable system." label="Enabled">
              <Checkbox
                id="SystemEnabledCheckbox"
                onChange={({ detail }) => systemEnabledFieldOnChangeHandler(detail)}
                checked={enabledValue === 'Y'}
              >
                Enabled
              </Checkbox>
            </FormField>
            <SpaceBetween size="xs" direction="horizontal">
              <Button onClick={cancelBtnHandler}>Cancel</Button>
              <Button onClick={undoBtnHandler} disabled={disableUndo}>Undo</Button>
              <Button onClick={saveBtnHandler} variant="primary">Save</Button>
            </SpaceBetween>
          </SpaceBetween>
        </Form>
      </Container>
    </div>
  );
}