import React from 'react';
import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import { ForceAwakensConfigNavigation } from '../navigation/index';
import SystemsMainContent from './MainContents';
import { appLayoutLabels } from 'src/common/labels';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import { IsItDown } from '../common/IsItDown';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { State } from '@hookstate/core';
import { RouteComponentProps } from 'react-router-dom';

interface ForceAwakensConfigurationSystemsLocalProps extends RouteComponentProps {
  forceAwakensState: State<ForceAwakensStateInterface>;
};

export class Systems extends React.Component<ForceAwakensConfigurationSystemsLocalProps> {

  state = {
    navigationOpen: true
  };

  onNavigationChange(event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) {
    this.setState({
      navigationOpen: event.detail.open
    });
  }

  render() {
    if (!this.props.forceAwakensState?.value.selectedSite) this.props.history.push('/');
    return (
      <div id="Systems">
        <AppLayout
          ariaLabels={appLayoutLabels}
          content={<SystemsMainContent forceAwakensState={this.props.forceAwakensState}/>}
          contentType="table"
          disableBodyScroll
          disableContentPaddings
          headerSelector='#topNavigation'
          navigation={<ForceAwakensConfigNavigation forceAwakensState={this.props.forceAwakensState}/>}
          navigationOpen={this.state.navigationOpen}
          notifications={<IsItDown />}
          onNavigationChange={event => this.onNavigationChange(event)}
          stickyNotifications
          toolsHide
        />
      </div>
    );
  }

}

export default Systems;