import React, { useState, useEffect } from 'react';
import { useState as useHookState } from '@hookstate/core';
import { Box, Button, CollectionPreferences, Flashbar, Modal, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from "../../API";
import API, { graphqlOperation } from '@aws-amplify/api';
import { deleteSystemV1 as deleteSystemMutation } from "../../graphql/mutations";
import { RefreshCallBackInterface } from "./MainContents";
import { SystemEditPanel } from './Edit';
import { SystemCreatePanel } from './Create';
import { forceAwakensBaseState } from '../../stores/app';
import { fetchPageSize } from 'src/utils/UserPreferences';
import { useBundle } from '@amzn/react-arb-tools';

export interface CancelCreateSystemInterface {
  (): void;
}
export interface CreatedSystemInterface {
  (createdSystem: APIt.System): void;
}
export interface CancelUpdateSystemInterface {
  (): void;
}
export interface UpdatedSystemInterface {
  (updatedSystem: APIt.System): void;
}
export interface SystemsTablePanelPropsInterface {
  systems: APIt.System[];
  isTableLoading: boolean;
  refreshCallback: RefreshCallBackInterface;
}

export default function SystemsTablePanel(props: SystemsTablePanelPropsInterface ) {

  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [allItems, setAllItems] = useState<APIt.System[]>(props.systems);
  const [pageSize, handlePageSizeChange] = fetchPageSize('systems',5);
  const [bundle, isBundleLoading] = useBundle('components.Systems.TablePanel');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  useEffect(() => {
    setAllItems(props.systems);
  }, [props.systems]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? "No Systems Found For Site" : bundle.getMessage('systems-not-found')}  />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: "id" }
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const [selectedItems, setSelectedItems] = useState<APIt.System[]>();
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = useState<APIt.System>();

  const cancelCreate: CancelCreateSystemInterface = () => {
    setShowCreateSystemPanel(false);
    setHideTable(false);
  }

  const cancelUpdate = () => {
    setShowEditSystemPanel(false);
    setHideTable(false);
  }

  const [showCreateSystemPanel, setShowCreateSystemPanel] = useState<boolean>(false);

  const [systemCreateSaved, setSystemCreateSaved] = useState<boolean>(false);

  const createBtnClickHandler = () => {
    setHideTable(true);
    setShowCreateSystemPanel(true);
  };

  const createdSystem: CreatedSystemInterface = (createdSystem: APIt.System) => {
    if (!allItems) return;
    const newAllItems = allItems;
    newAllItems.push(createdSystem);
    setAllItems(newAllItems);
    setHideTable(false);
    setSystemCreateSaved(true);
    setShowCreateSystemPanel(false);
  }

  const resetSystemCreatedSaved = () => {
    setSystemCreateSaved(false);
  };

  const FlashMessageCreateSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: isBundleLoading ? 'New System Created' : bundle.getMessage('system-created'),
            buttonText: 'OK',
            onButtonClick: resetSystemCreatedSaved
          },
        ]
      }
    />
  );

  const [showEditSystemPanel, setShowEditSystemPanel] = useState<boolean>(false);

  const [systemEditSaved, setSystemEditSaved] = useState<boolean>(false);

  const editBtnClickHandler = () => {
    setShowEditSystemPanel(true);
    setHideTable(true);
  };

  const updatedSystem: UpdatedSystemInterface = (updatedSystem: APIt.System) => {
    if (!allItems) return;
    const newAllItems = allItems.filter( (item) => item.id !== updatedSystem.id );
    newAllItems.push(updatedSystem);
    setAllItems(newAllItems);
    setShowEditSystemPanel(false);
    setHideTable(false);
    setSystemEditSaved(true);
    setSelectedSystem(updatedSystem);
  };

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      setSelectedSystem(selectedItems[0]);
    }
  }, [selectedItems]);

  const resetSystemEditSaved = () => {
    setSystemEditSaved(false);
  };

  const FlashMessageEditSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: isBundleLoading ? 'System Change Saved' : bundle.getMessage('system-updated'),
            buttonText: isBundleLoading ? 'OK' : bundle.getMessage('ok'),
            onButtonClick: resetSystemEditSaved
          },
        ]
      }
    />
  );

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [systemDeleteSaved, setSystemDeleteSaved] = useState<boolean>(false);

  const deleteBtnClickHandler = () => {
    if (selectedSystem && selectedSystem.id) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const deleteYesBtnClickHandler = () => {
    if (selectedSystem && selectedSystem.id) deleteSystem(selectedSystem.id);
  };

  const deleteSystem = async (id: string) => {
    try {
      await API.graphql(graphqlOperation(deleteSystemMutation,
        {
          input:
            {
              id: id,
              updated_by: forceAwakensState.username.value
            }
        }));
    } catch (e) {
      console.log(`deleteSystem(): exception is ${e}`);
    }
    setSelectedSystem(undefined);
    const newAllItems = allItems!.filter( (item) => item.id !== id );
    setAllItems(newAllItems);
    setDeleteVisible(false);
    setSystemDeleteSaved(true);
  }

  const resetSystemDeleteSaved = () => {
    setSystemDeleteSaved(false);
  };

  const FlashMessageDeleteSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: isBundleLoading ? 'System Deleted' : bundle.getMessage('system-deleted'),
            buttonText: 'OK',
            onButtonClick: resetSystemDeleteSaved
          },
        ]
      }
    />
  );

  const refreshBtnClickHandler = () => {
    props.refreshCallback();
  };

  const refresh = async (id: string) => {
    try {
      await API.graphql(graphqlOperation(deleteSystemMutation, { input: { id: id } }));
    } catch (e) {
      console.log(`deleteSystem(): exception is ${e}`);
    }
    setSelectedSystem(undefined);
    const newAllItems = allItems!.filter( (item) => item.id !== id );
    setAllItems(newAllItems);
  }

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  }
  if (isBundleLoading) return <Spinner/>;
  return(
    <>
    {systemEditSaved && <FlashMessageEditSaved/>}
    {systemCreateSaved && <FlashMessageCreateSaved/>}
    {systemDeleteSaved && <FlashMessageDeleteSaved/>}
    <div id="tableDiv" hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Filter Systems"
            filteringPlaceholder= {isBundleLoading ? "Find Systems" : bundle.getMessage('find-system') }
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size="xs" direction="horizontal">
                <Button onClick={refreshBtnClickHandler} iconName="refresh"/>
                <Button onClick={editBtnClickHandler} disabled={selectedSystem === undefined}>Edit</Button>
                <Button onClick={deleteBtnClickHandler} disabled={selectedSystem === undefined}>Delete</Button>
                <Button variant="primary" onClick={createBtnClickHandler}>Create</Button>
              </SpaceBetween>
              </>
            }
          >
            Systems for {forceAwakensState.value.selectedSite?.SegmentName}
          </Header>
        }
        items={items}
        loading={props.isTableLoading}
        loadingText= {isBundleLoading ? "Loading systems, please wait..." : bundle.getMessage('loading') }
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => handlePageSizeChange(detail.pageSize || pageSize)}
            title={bundle.getMessage('user-preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              pageSize: pageSize,
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 5, label: bundle.formatMessage('number-of-devices', { deviceCount: 5 }) },
                { value: 10, label: bundle.formatMessage('number-of-devices', { deviceCount: 10 }) },
                { value: 15, label: bundle.formatMessage('number-of-devices', { deviceCount: 15 }) },
                { value: 20, label: bundle.formatMessage('number-of-devices', { deviceCount: 20 }) },
                { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25 }) },
              ],
            }}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType="single"
        stickyHeader={false}
        trackBy="id"
      />
    </div>
    <Modal
      onDismiss={() => setDeleteVisible(false)}
      visible={deleteVisible}
      closeAriaLabel="Close"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={deleteNoBtnClickHandler}>No</Button>
            <Button variant="primary" onClick={deleteYesBtnClickHandler}>Yes</Button>
          </SpaceBetween>
        </Box>
      }
      header="Confirm Delete"
    >
      Delete the system {selectedSystem?.name}?
    </Modal>
    {
      selectedSystem
      && showEditSystemPanel
      && <SystemEditPanel
        cancelCallback={cancelUpdate}
        saveCallback={updatedSystem}
        selectedSystem={selectedSystem}
        secondarySystemOptions={[
          {
            label: 'None',
            value: '0'
          },
          ...props.systems.filter(system => system.id !== selectedSystem?.id).map( (system) => {
            return(
              {
                label: system.name,
                value: system.id.toString()
              });
            })
        ]} />
    }
    {
      showCreateSystemPanel
      && <SystemCreatePanel
        cancelCreateCallback={cancelCreate}
        saveCallback={createdSystem}
        secondarySystemOptions={[
          {
            label: 'None',
            value: '0'
          },
          ...props.systems.filter(system => system.id !== selectedSystem?.id).map( (system) => {
            return(
              {
                label: system.name,
                value: system.id.toString()
              });
            })
        ]} />
    }
    </>
  );
}
