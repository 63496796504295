import { LocalizationContextBuilder } from "@amzn/arb-tools";

export const AUTHENTICATION_MODES = [
  { label: 'BASIC', value: 'BASIC'},
  { label: 'NTLM', value: 'NTLM'},
  { label: 'NTLM_OR_BASIC', value: 'NTLM_OR_BASIC'},
];

export const DEFAULT_LOCALE = navigator.language || 'en-US';
export const DEFAULT_LC_BUILDER = new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);

export interface ILanguage {
  id: string;
  text: string;
}
export const Languages: ILanguage[] = [
  {
    id: "en-US",
    text: "English",
  },
  {
    id: "fr-FR",
    text: "French",
  },
  {
    id: "de-DE",
    text: "German",
  },
  {
    id: "es-ES",
    text: "Spanish",
  },
  {
    id: "pt-PT",
    text: "Portuguese",
  },
  {
    id: "it-IT",
    text: "Italian",
  },
  {
    id: "hi-IN",
    text: "Hindi",
  },
  {
    id: "ja-JP",
    text: "Japanese",
  },
  {
    id: "ar-AE",
    text: "Arabic (U.A.E.)",
  },
  {
    id: "ar-SA",
    text: "Arabic (Saudi Arabia)",
  },
  {
    id: "cs-CZ",
    text: "Czech (Czech Republic)",
  },
  {
    id: "en-AU",
    text: "English (Australia)",
  },
  {
    id: "en-CA",
    text: "English (Canada)",
  },
  {
    id: "en-GB",
    text: "English (United Kingdom)",
  },
  {
    id: "en-SG",
    text: "English (Singapore)",
  },
  {
    id: "es-MX",
    text: "Spanish (Mexico)",
  },
  {
    id: "fr-CA",
    text: "French (Canada)",
  },
  {
    id: "nl-NL",
    text: "Dutch (Netherlands)",
  },
  {
    id: "pl-PL",
    text: "Polish (Poland)",
  },
  {
    id: "sk-SK",
    text: "Slovak (Slovakia)",
  },
  {
    id: "tr-TR",
    text: "Turkish (Turkey)",
  },
  {
    id: "zh-CN",
    text: "Chinese (S)",
  },
  {
    id: "zh-TW",
    text: "Chinese (T)",
  },
];

export const MIDWAY = {
  URL: 'https://midway-auth.amazon.com/SSO',
  SCOPE: 'openid',
  RESPONSE_TYPE: 'id_token',
  SENTRY_HANDLER_VERSION: 'MidwayNginxModule-1.3-1'
};

export const URLS = {
  Contact: 'https://w.amazon.com/index.php/SIDE#HContactus',
  GuardianHub: 'https://guardian.gso.amazon.dev/',
  RiverGuide: 'https://river.amazon.com/?org=AccSys&q0=605c02a4-4e1e-49d1-96a4-5e9b5017a1bf',
  SiteNotFound: 'https://w.amazon.com/bin/view/SOT_Knowledge_Center/Video_Systems/Integrations/ForceAwakens/#HNotseeingthesitesyouexpect3F',
  UserGuide: 'https://w.amazon.com/bin/view/SOT_Knowledge_Center/Video_Systems/Integrations/ForceAwakens/',
};

export enum UserActionNames {
  AuthenticateUser = "Authenticate User",
  AuthenticateUserError = "Error: Authenticate User",
  AuditDataSearch = "Audit Data Search",
  AuditDataSearchError = "Audit Data Search Error",
  CreateDeviceLink = "Create Device Link",
  CreateDeviceLinkError = "Create Device Link Error",
  CreateDeviceLinkEvent = "Create Device Link Event",
  CreateDeviceLinkEvError = "Create Device Link Event Error",
  CreateSystem = "Create System",
  CreateSystemError = "Create System Error",
  DeleteDeviceLink = "Delete Device Link",
  DeleteDeviceLinkError = "Delete Device Link Error",
  DeleteDeviceLinkEvent = "Delete Device Link Event",
  DeleteDeviceLinkEvError = "Delete Device Link Event Error",
  SelectSite = "Select Site",
  UpdateDeviceLink = "Update Device Link",
  UpdateDeviceLinkError = "Update Device Link Error",
  UpdateDeviceLinkEvent = "Update Device Link Event",
  UpdateDeviceLinkEvError = "Update Device Link Event Error",
  UpdateSystem = "Update System",
  UpdateSystemError = "Update System Error",
  UIModeChange =  "UI Mode Change",
};
