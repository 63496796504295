import React from "react";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from "../../API";
import { listSystemsForSiteV1 } from '../../graphql/queries';
import SystemsTablePanel from './TablePanel';
import { ForceAwakensStateInterface } from "src/stores/app";
import { State } from "@hookstate/core";
export interface SystemsMainContentPropsInterface {
  forceAwakensState: State<ForceAwakensStateInterface>;
}
export interface RefreshCallBackInterface {
  (): void;
}
export interface SystemsMainContentStateInterface {
  isTableLoading: boolean;
  systems: APIt.System[];
}
export default class SystemsMainContent extends React.Component
  <SystemsMainContentPropsInterface, SystemsMainContentStateInterface>
{
  private _isMounted: boolean;

  constructor(props: SystemsMainContentPropsInterface) {
    super(props);
    this._isMounted = false;
    this.state = {
      isTableLoading: false,
      systems: []
    };
  }

  refreshSystems = async (): Promise<void> => {
    const siteCode = this.props.forceAwakensState.get().selectedSite?.siteCode;
    if (!this._isMounted) return;
    this.setState(
      {
        systems: [],
        isTableLoading: true,
      });
    const response = await API.graphql(graphqlOperation(listSystemsForSiteV1,
      {
        segment_name: siteCode
      })) as GraphQLResult<APIt.ListSystemsForSiteV1Query>;
    this._isMounted && this.setState(
      {
        systems: response.data?.listSystemsForSiteV1 as APIt.System[],
        isTableLoading: false,
      });
  }

  componentDidMount() {
    this._isMounted = true;
    this.refreshSystems();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps: Readonly<SystemsMainContentPropsInterface>, nextContext: any): void {
    this.refreshSystems(); 
  }

  render() {
    return (
      <SystemsTablePanel
        systems={this.state.systems}
        isTableLoading={this.state.isTableLoading}
        refreshCallback={this.refreshSystems}
      />
    );
  }
}