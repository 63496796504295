import React from "react";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SideNavigation, { SideNavigationProps } from "@amzn/awsui-components-react/polaris/side-navigation";
import { ForceAwakensStateInterface } from "src/stores/app";
import { State } from "@hookstate/core";
import { isAdmin } from '../../utils/UserPermissionUtils';

interface ForceAwakensConfigurationNavigationLocalProps extends RouteComponentProps {
  forceAwakensState?: State<ForceAwakensStateInterface>;
};
class ForceAwakensConfigNavigationLocal extends React.Component<ForceAwakensConfigurationNavigationLocalProps> {

    // If the provided link is empty, do not redirect pages
    onFollowHandler(event: CustomEvent<SideNavigationProps.FollowDetail>) {
      event.preventDefault();
      if (event.detail.href) {
        this.props.history.push(event.detail.href.substring(1));
      }
    }

    checkUserIsAdmin() {
      const username = this.props.forceAwakensState?.username.get();
      if (this.props.forceAwakensState?.isAdmin.get() === undefined)  {
        isAdmin(username!).then((result) => {
          this.props.forceAwakensState?.isAdmin.set(result);
        });
      }
    }

    render() {

      if (this.props.location.pathname === '/') this.props.location.pathname = "/DeviceLinks";

      this.checkUserIsAdmin();

      const username = this.props.forceAwakensState?.username.get();
      const userIsAdmin = this.props.forceAwakensState?.isAdmin.get();
      const stage = 'beta';
      const home = {
        text: 'ForceAwakens Configuration',
        href: '#/'
      };

      let sideNavigationItems: SideNavigationProps.Item[] = [
        {
          type: "link",
          text: "Device Links",
          href: "#/DeviceLinks"
        }];
      if (username && userIsAdmin) {
        sideNavigationItems.push(
          {
            type: "link",
            text: "Systems",
            href: "#/Systems"
          });
        sideNavigationItems.push(
          {
            type: "link",
            text: "Audit",
            href: "#/Audit"
          });
      }
      return (
        <SideNavigation
          activeHref={`#${this.props.location.pathname}`}
          header={home}
          items={sideNavigationItems}
          onFollow={this.onFollowHandler.bind(this)}
        />);
  }
}

export const ForceAwakensConfigNavigation = withRouter(ForceAwakensConfigNavigationLocal);