/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getCachedSiteCameras = /* GraphQL */ `query GetCachedSiteCameras($siteCode: String!) {
  getCachedSiteCameras(siteCode: $siteCode) {
    siteCode
    created
    cameras {
      name
      description
      systemIdentifier
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCachedSiteCamerasQueryVariables,
  APITypes.GetCachedSiteCamerasQuery
>;
export const getCachedSiteCamerasV1 = /* GraphQL */ `query GetCachedSiteCamerasV1($siteCode: String!) {
  getCachedSiteCamerasV1(siteCode: $siteCode) {
    siteCode
    created
    cameras {
      name
      description
      systemIdentifier
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCachedSiteCamerasV1QueryVariables,
  APITypes.GetCachedSiteCamerasV1Query
>;
export const getDeviceLink = /* GraphQL */ `query GetDeviceLink($id: ID!) {
  getDeviceLink(id: $id) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkQueryVariables,
  APITypes.GetDeviceLinkQuery
>;
export const getDeviceLinkV1 = /* GraphQL */ `query GetDeviceLinkV1($id: ID!) {
  getDeviceLinkV1(id: $id) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkV1QueryVariables,
  APITypes.GetDeviceLinkV1Query
>;
export const getDeviceLinkEvent = /* GraphQL */ `query GetDeviceLinkEvent($id: ID!) {
  getDeviceLinkEvent(id: $id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkEventQueryVariables,
  APITypes.GetDeviceLinkEventQuery
>;
export const getDeviceLinkEventV1 = /* GraphQL */ `query GetDeviceLinkEventV1($id: ID!) {
  getDeviceLinkEventV1(id: $id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeviceLinkEventV1QueryVariables,
  APITypes.GetDeviceLinkEventV1Query
>;
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>;
export const getEventV1 = /* GraphQL */ `query GetEventV1($id: ID!) {
  getEventV1(id: $id) {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventV1QueryVariables,
  APITypes.GetEventV1Query
>;
export const getEventLinksForSystemId = /* GraphQL */ `query GetEventLinksForSystemId($system_id: ID!) {
  getEventLinksForSystemId(system_id: $system_id) {
    event_links {
      device_link_id
      devices {
        cameraName
        cameraSystemIdentifier
        accessControlDeviceName
        accessControlDeviceParentDeviceID
        accessControlDeviceChildDeviceID
        accessControlDeviceSubchildDeviceID
        accessControlDeviceType
        __typename
      }
      events
      system {
        auth_mode
        system_id
        secondary_system_id
        facman_sitename
        srvr_alrm_srvc_path
        srvr_alrm_srvc_port
        srvr_cmd_srvc_path
        srvr_cmd_srvc_port
        srvr_cnf_srvc_path
        srvr_cnf_srvc_port
        srvr_vpc_endpoint
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLinksForSystemIdQueryVariables,
  APITypes.GetEventLinksForSystemIdQuery
>;
export const getEventLinksForSystemIdV1 = /* GraphQL */ `query GetEventLinksForSystemIdV1($system_id: ID!) {
  getEventLinksForSystemIdV1(system_id: $system_id) {
    event_links {
      device_link_id
      devices {
        cameraName
        cameraSystemIdentifier
        accessControlDeviceName
        accessControlDeviceParentDeviceID
        accessControlDeviceChildDeviceID
        accessControlDeviceSubchildDeviceID
        accessControlDeviceType
        __typename
      }
      events
      system {
        auth_mode
        system_id
        secondary_system_id
        facman_sitename
        srvr_alrm_srvc_path
        srvr_alrm_srvc_port
        srvr_cmd_srvc_path
        srvr_cmd_srvc_port
        srvr_cnf_srvc_path
        srvr_cnf_srvc_port
        srvr_vpc_endpoint
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLinksForSystemIdV1QueryVariables,
  APITypes.GetEventLinksForSystemIdV1Query
>;
export const getSystem = /* GraphQL */ `query GetSystem($id: ID!) {
  getSystem(id: $id) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSystemQueryVariables, APITypes.GetSystemQuery>;
export const getSystemV1 = /* GraphQL */ `query GetSystemV1($id: ID!) {
  getSystemV1(id: $id) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemV1QueryVariables,
  APITypes.GetSystemV1Query
>;
export const getSystemForSegmentName = /* GraphQL */ `query GetSystemForSegmentName($segmentName: String!) {
  getSystemForSegmentName(segmentName: $segmentName) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemForSegmentNameQueryVariables,
  APITypes.GetSystemForSegmentNameQuery
>;
export const getSystemForSegmentNameV1 = /* GraphQL */ `query GetSystemForSegmentNameV1($segmentName: String!) {
  getSystemForSegmentNameV1(segmentName: $segmentName) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSystemForSegmentNameV1QueryVariables,
  APITypes.GetSystemForSegmentNameV1Query
>;
export const listAccessControlDevices = /* GraphQL */ `query ListAccessControlDevices($path: String!) {
  listAccessControlDevices(path: $path) {
    Parent_DeviceID
    Child_DeviceID
    Subchild_DeviceID
    Device_Type
    DeviceName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessControlDevicesQueryVariables,
  APITypes.ListAccessControlDevicesQuery
>;
export const listAccessControlDevicesV1 = /* GraphQL */ `query ListAccessControlDevicesV1($path: String!) {
  listAccessControlDevicesV1(path: $path) {
    Parent_DeviceID
    Child_DeviceID
    Subchild_DeviceID
    Device_Type
    DeviceName
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccessControlDevicesV1QueryVariables,
  APITypes.ListAccessControlDevicesV1Query
>;
export const listAudit = /* GraphQL */ `query ListAudit(
  $startDate: String!
  $endDate: String!
  $limit: Int!
  $offset: Int!
) {
  listAudit(
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    offset: $offset
  ) {
    event_id
    table_name
    action_tstamp_tx
    action
    row_data
    row_data_json
    changed_fields
    changed_fields_json
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAuditQueryVariables, APITypes.ListAuditQuery>;
export const listAuditV1 = /* GraphQL */ `query ListAuditV1(
  $startDate: String!
  $endDate: String!
  $limit: Int!
  $offset: Int!
) {
  listAuditV1(
    startDate: $startDate
    endDate: $endDate
    limit: $limit
    offset: $offset
  ) {
    event_id
    table_name
    action_tstamp_tx
    action
    row_data
    row_data_json
    changed_fields
    changed_fields_json
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuditV1QueryVariables,
  APITypes.ListAuditV1Query
>;
export const listSiteCameras = /* GraphQL */ `query ListSiteCameras(
  $siteCode: String!
  $authMode: String!
  $cmdSrvcEndpoint: String!
  $cmdSrvcPath: String!
  $cmdSrvcPort: String!
) {
  listSiteCameras(
    siteCode: $siteCode
    authMode: $authMode
    cmdSrvcEndpoint: $cmdSrvcEndpoint
    cmdSrvcPath: $cmdSrvcPath
    cmdSrvcPort: $cmdSrvcPort
  ) {
    name
    description
    systemIdentifier
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSiteCamerasQueryVariables,
  APITypes.ListSiteCamerasQuery
>;
export const listSiteCamerasV1 = /* GraphQL */ `query ListSiteCamerasV1(
  $siteCode: String!
  $authMode: String!
  $cmdSrvcEndpoint: String!
  $cmdSrvcPath: String!
  $cmdSrvcPort: String!
) {
  listSiteCamerasV1(
    siteCode: $siteCode
    authMode: $authMode
    cmdSrvcEndpoint: $cmdSrvcEndpoint
    cmdSrvcPath: $cmdSrvcPath
    cmdSrvcPort: $cmdSrvcPort
  ) {
    name
    description
    systemIdentifier
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSiteCamerasV1QueryVariables,
  APITypes.ListSiteCamerasV1Query
>;
export const listDeviceLinkEventsForDeviceLink = /* GraphQL */ `query ListDeviceLinkEventsForDeviceLink($device_link_id: ID!) {
  listDeviceLinkEventsForDeviceLink(device_link_id: $device_link_id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinkEventsForDeviceLinkQueryVariables,
  APITypes.ListDeviceLinkEventsForDeviceLinkQuery
>;
export const listDeviceLinkEventsForDeviceLinkV1 = /* GraphQL */ `query ListDeviceLinkEventsForDeviceLinkV1($device_link_id: ID!) {
  listDeviceLinkEventsForDeviceLinkV1(device_link_id: $device_link_id) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinkEventsForDeviceLinkV1QueryVariables,
  APITypes.ListDeviceLinkEventsForDeviceLinkV1Query
>;
export const listDeviceLinksForSite = /* GraphQL */ `query ListDeviceLinksForSite($segment_name: String!) {
  listDeviceLinksForSite(segment_name: $segment_name) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinksForSiteQueryVariables,
  APITypes.ListDeviceLinksForSiteQuery
>;
export const listDeviceLinksForSiteV1 = /* GraphQL */ `query ListDeviceLinksForSiteV1($segment_name: String!) {
  listDeviceLinksForSiteV1(segment_name: $segment_name) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeviceLinksForSiteV1QueryVariables,
  APITypes.ListDeviceLinksForSiteV1Query
>;
export const listEvents = /* GraphQL */ `query ListEvents {
  listEvents {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsQueryVariables,
  APITypes.ListEventsQuery
>;
export const listEventsV1 = /* GraphQL */ `query ListEventsV1 {
  listEventsV1 {
    created
    created_by
    device_type
    id
    name
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEventsV1QueryVariables,
  APITypes.ListEventsV1Query
>;
export const listUserPermission = /* GraphQL */ `query ListUserPermission($path: String!) {
  listUserPermission(path: $path) {
    HASPERMISSION
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPermissionQueryVariables,
  APITypes.ListUserPermissionQuery
>;
export const listUserPermissionV1 = /* GraphQL */ `query ListUserPermissionV1($path: String!) {
  listUserPermissionV1(path: $path) {
    HASPERMISSION
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserPermissionV1QueryVariables,
  APITypes.ListUserPermissionV1Query
>;
export const listUserSites = /* GraphQL */ `query ListUserSites($path: String!) {
  listUserSites(path: $path) {
    SegmentName
    SegmentLocation
    SegmentSource
    SiteCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSitesQueryVariables,
  APITypes.ListUserSitesQuery
>;
export const listUserSitesV1 = /* GraphQL */ `query ListUserSitesV1($path: String!) {
  listUserSitesV1(path: $path) {
    SegmentName
    SegmentLocation
    SegmentSource
    SiteCode
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserSitesV1QueryVariables,
  APITypes.ListUserSitesV1Query
>;
export const listSIGInfraUserSites = /* GraphQL */ `query ListSIGInfraUserSites($username: String!) {
  listSIGInfraUserSites(username: $username) {
    allSites
    sites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSIGInfraUserSitesQueryVariables,
  APITypes.ListSIGInfraUserSitesQuery
>;
export const listSIGInfraUserSitesV1 = /* GraphQL */ `query ListSIGInfraUserSitesV1($username: String!) {
  listSIGInfraUserSitesV1(username: $username) {
    allSites
    sites
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSIGInfraUserSitesV1QueryVariables,
  APITypes.ListSIGInfraUserSitesV1Query
>;
export const listSystems = /* GraphQL */ `query ListSystems {
  listSystems {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsQueryVariables,
  APITypes.ListSystemsQuery
>;
export const listSystemsV1 = /* GraphQL */ `query ListSystemsV1 {
  listSystemsV1 {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsV1QueryVariables,
  APITypes.ListSystemsV1Query
>;
export const listSystemsForSite = /* GraphQL */ `query ListSystemsForSite($segment_name: String!) {
  listSystemsForSite(segment_name: $segment_name) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsForSiteQueryVariables,
  APITypes.ListSystemsForSiteQuery
>;
export const listSystemsForSiteV1 = /* GraphQL */ `query ListSystemsForSiteV1($segment_name: String!) {
  listSystemsForSiteV1(segment_name: $segment_name) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSystemsForSiteV1QueryVariables,
  APITypes.ListSystemsForSiteV1Query
>;
