import React from "react";
import * as APIt from "../../API";
import AuditTablePanel from './TablePanel';
import { ForceAwakensStateInterface } from "src/stores/app";
import { State } from "@hookstate/core";

export interface AuditMainContentPropsInterface {
  forceAwakensState: State<ForceAwakensStateInterface>;
}
export interface RefreshCallBackInterface {
  (): void;
}
interface AuditMainContentStateInterface {
  audits: APIt.Audit[];
  isTableLoading: boolean;
}
export default class AuditMainContent extends React.Component
  <AuditMainContentPropsInterface, AuditMainContentStateInterface>
{
  private _isMounted: boolean;

  constructor(props: AuditMainContentPropsInterface) {
    super(props);
    this._isMounted = false;
    this.state = {
      isTableLoading: false,
      audits: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <AuditTablePanel />
    );
  }
}