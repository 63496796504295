import React, { useEffect, useState } from 'react';
import { Box, Button, CollectionPreferences, Flashbar, Modal, Pagination, SpaceBetween, Spinner, Table, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { State } from '@hookstate/core';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState, VisibleColumns } from './table-config';
import * as APIt from "../../API";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { deleteDeviceLinkV1 as deleteDeviceLinkMutation } from "../../graphql/mutations";
import { RefreshAllCallBackInterface, RefreshEventOptionsCallbackInterface, RefreshDeviceOptionsCallBackInterface, RefreshCameraOptionsCallBackInterface } from "./MainContents";
import { SelectOptionInterface } from "./MainContents";
import { DeviceLinkEditPanel } from './Edit';
import { DeviceLinkCreatePanel } from './Create';
import DeviceLinkEventsTablePanel from './ChildTablePanel';
import { listDeviceLinkEventsForDeviceLinkV1, listEventsV1 } from 'src/graphql/queries';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { useBundle } from '@amzn/react-arb-tools';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { UserActionNames } from 'src/constants/Constants';
import { FetchCameraImage } from 'src/utils/FetchCameraImage';
import { fetchPageSize } from 'src/utils/UserPreferences';

export interface CancelCreateDeviceLinkInterface {
  (): void;
}
export interface CreatedDeviceLinkInterface {
  (): void;
}
export interface CancelUpdateDeviceLinkInterface {
  (): void;
}
export interface UpdatedDeviceLinkInterface {
  (updatedDeviceLink: APIt.DeviceLink): void;
}
export interface DeviceLinksTablePanelPropsInterface {
  acpDeviceOptions: SelectOptionInterface[];
  acpDeviceOptionsLoading: boolean;
  cameraDeviceOptions: SelectOptionInterface[];
  cameraDeviceOptionsLoading: boolean;
  deviceLinks: APIt.DeviceLink[];
  forceAwakensState: State<ForceAwakensStateInterface>;
  eventOptions: SelectOptionInterface[];
  isTableLoading: boolean;
  refreshAllCallback: RefreshAllCallBackInterface;
  refreshEventOptionsCallback: RefreshEventOptionsCallbackInterface;
  refreshDeviceOptionsCallback: RefreshDeviceOptionsCallBackInterface;
  refreshCameraOptionsCallback: RefreshCameraOptionsCallBackInterface;
  systemOptions: SelectOptionInterface[];
}

export default function DeviceLinksTablePanel(props: DeviceLinksTablePanelPropsInterface ) {

  const [allItems, setAllItems] = useState<APIt.DeviceLink[]>(props.deviceLinks);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(props.isTableLoading);
  const [pageSize, handlePageSizeChange] = fetchPageSize('device_links',15);

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.TablePanel');

  const { timeStamp, blobUrl, cameraImage, loading, fetchImage } = FetchCameraImage();

  useEffect(() => {
    setAllItems(props.deviceLinks);
  }, [props.deviceLinks]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: "id" }
    }
  );

  let deviceLinkItems = items;

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const [selectedItems, setSelectedItems] = useState<APIt.DeviceLink[]>([]);

  const [hideDeviceLinksTable, setHideDeviceLinksTable] = useState<boolean>(false);
  const [hideDeviceLinkEventsTable, setHideDeviceLinkEventsTable] = useState<boolean>(true);

  const cancelCreate: CancelCreateDeviceLinkInterface = () => {
    setShowCreateDeviceLinkPanel(false);
    setHideDeviceLinksTable(false);
    setHideDeviceLinkEventsTable(false);
  }

  const cancelUpdate = () => {
    setShowEditDeviceLinkPanel(false);
    setHideDeviceLinksTable(false);
    setHideDeviceLinkEventsTable(false);
  }

  const [showCreateDeviceLinkPanel, setShowCreateDeviceLinkPanel] = useState<boolean>(false);

  const [deviceLinkCreateSaved, setDeviceLinkCreateSaved] = useState<boolean>(false);

  const createBtnClickHandler = () => {
    setHideDeviceLinksTable(true);
    setHideDeviceLinkEventsTable(true);
    setShowCreateDeviceLinkPanel(true);
  };

  const [events, setEvents] = useState<APIt.Event[]>();

  const refreshEvents = async (): Promise<void> => {
    if (!selectedItems || !selectedItems[0]) return;
    try {
      const response = await API.graphql(graphqlOperation(
        listEventsV1,
        {
          input: {}
          })) as GraphQLResult<APIt.ListEventsV1Query>;
      if (response && response.data && response.data.listEventsV1) {
        const events = response.data.listEventsV1 as APIt.Event[];
        setEvents(events);
      }
    } catch(e) {
      console.log(`refreshEvents(): exception is ${JSON.stringify(e)}`);
    }
  }

  const [deviceLinkEvents, setDeviceLinkEvents] = useState<APIt.DeviceLinkEvent[]>();

  const [isDeviceLinkEventsTableLoading, setIsDeviceLinkEventsTableLoading] = useState<boolean>(false);

  const refreshDeviceLinkEvents = async (): Promise<void> => {
    if (!selectedItems || !selectedItems[0]) return;
    setIsDeviceLinkEventsTableLoading(true);
    try {
      const response = await API.graphql(graphqlOperation(
        listDeviceLinkEventsForDeviceLinkV1,
        {
          device_link_id: selectedItems[0].id
        })) as GraphQLResult<APIt.ListDeviceLinkEventsForDeviceLinkV1Query>;
      if (response && response.data && response.data.listDeviceLinkEventsForDeviceLinkV1) {
        const deviceLinkEventsTemp = response.data.listDeviceLinkEventsForDeviceLinkV1 as APIt.DeviceLinkEvent[];
        setDeviceLinkEvents(deviceLinkEventsTemp);
        setHideDeviceLinkEventsTable(false);
      }
    } catch(e) {
      console.log(`refreshDeviceLinkEvents(): exception is ${JSON.stringify(e)}`);
    }
    setIsDeviceLinkEventsTableLoading(false);
  }

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      if (selectedItems[0]) refreshDeviceLinkEvents();
      if (selectedItems[0]) refreshEvents();
      if (selectedItems[0]) props.refreshEventOptionsCallback(selectedItems[0].acd_device_type);
    }
  }, [selectedItems]);

  useEffect(() => {
    setIsTableLoading(props.isTableLoading);
  }, [props.isTableLoading])
  
  const createdDeviceLinks: CreatedDeviceLinkInterface = () => {
    if (!allItems) return;
    setHideDeviceLinksTable(false);
    setHideDeviceLinkEventsTable(false);
    props.refreshAllCallback();
    setShowCreateDeviceLinkPanel(false);
    setDeviceLinkCreateSaved(true);
  }

  const resetDeviceLinkCreatedSaved = () => {
    setDeviceLinkCreateSaved(false);
  };

  const FlashMessageCreateSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('created'),
            buttonText: 'OK',
            onButtonClick: resetDeviceLinkCreatedSaved
          },
        ]
      }
    />
  );

  const [showEditDeviceLinkPanel, setShowEditDeviceLinkPanel] = useState<boolean>(false);

  const [deviceLinkEditSaved, setDeviceLinkEditSaved] = useState<boolean>(false);

  const editBtnClickHandler = () => {
    setShowEditDeviceLinkPanel(true);
    setHideDeviceLinksTable(true);
    setHideDeviceLinkEventsTable(true);
  };

  const updatedDeviceLink: UpdatedDeviceLinkInterface = (updatedDeviceLink: APIt.DeviceLink) => {
    if (!allItems) return;
    const newAllItems = allItems.filter( (item) => item.id !== updatedDeviceLink.id );
    newAllItems.push(updatedDeviceLink);
    setAllItems(newAllItems);
    setShowEditDeviceLinkPanel(false);
    setHideDeviceLinksTable(false);
    setHideDeviceLinkEventsTable(false);
    setDeviceLinkEditSaved(true);
    setSelectedItems([updatedDeviceLink]);
  };

  const resetDeviceLinkEditSaved = () => {
    setDeviceLinkEditSaved(false);
  };

  const FlashMessageEditSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('saved'),
            buttonText: 'OK',
            onButtonClick: resetDeviceLinkEditSaved
          },
        ]
      }
    />
  );

  const [deleteVisible, setDeleteVisible] = useState<boolean>(false);
  const [deviceLinkDeleteSaved, setDeviceLinkDeleteSaved] = useState<boolean>(false);

  const deleteBtnClickHandler = () => {
    if (selectedItems.length > 0) setDeleteVisible(true);
  };

  const deleteNoBtnClickHandler = () => {
    setDeleteVisible(false);
  };

  const deleteYesBtnClickHandler = async () => {
    setDeleting(true);
    for (let selectedItem of selectedItems) {
      await deleteDeviceLink(selectedItem.id);
    }
    setSelectedItems([]);
    setDeleteVisible(false);
    setDeviceLinkDeleteSaved(true);
    props.refreshAllCallback();
    setDeleting(false);
  };

  const deleteDeviceLink = async (id: string) => {
    try {
      await API.graphql(graphqlOperation(deleteDeviceLinkMutation,
        {
          input:
            {
              id: id,
              updated_by: props.forceAwakensState.username.value
            }
        }));
        createUserAction({
          actionName: UserActionNames.DeleteDeviceLink,
          username: props.forceAwakensState.username.value,
          parameters: JSON.stringify(
            {
              device_link_id: id,
            }
          )
        });
    } catch (e) {
      createUserAction({
        actionName: UserActionNames.DeleteDeviceLinkError,
        username: props.forceAwakensState.username.value,
        parameters: JSON.stringify(
          {
            device_link_id: id,
            deleteError: e,
          }
        )
      });
      console.log(`deleteDeviceLink(): exception is ${e}`);
      setDeleting(false);
      throw e;
    }
  }

  const resetDeviceLinkDeleteSaved = () => {
    setDeviceLinkDeleteSaved(false);
  };

  const FlashMessageDeleteSaved = () => (
    <Flashbar
      items = {
        [
          {
            type: 'info',
            dismissible: false,
            content: bundle.getMessage('deleted'),
            buttonText: 'OK',
            onButtonClick: resetDeviceLinkDeleteSaved
          },
        ]
      }
    />
  );

  const refreshBtnClickHandler = () => {
    setSelectedItems([]);
    props.refreshAllCallback();
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  useEffect(() => {
    setSelectedItems([]);
  }, [props.forceAwakensState]);

  if (isBundleLoading) return <Spinner/>;
  
  return(
    <>
    {deviceLinkEditSaved && <FlashMessageEditSaved/>}
    {deviceLinkCreateSaved && <FlashMessageCreateSaved/>}
    {deviceLinkDeleteSaved && <FlashMessageDeleteSaved/>}
    <div id="deviceLinkTableDiv" hidden={hideDeviceLinksTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions({timeStamp, blobUrl, cameraImage, loading, fetchImage})}
        visibleColumns={VisibleColumns}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Filter Device Link Events"
            filteringPlaceholder="Find Device Link Events"
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size="xs" direction="horizontal">
                <Button onClick={refreshBtnClickHandler} iconName="refresh"/>
                <Button onClick={editBtnClickHandler} disabled={selectedItems.length !== 1}>{bundle.getMessage('edit')}</Button>
                <Button onClick={deleteBtnClickHandler} disabled={selectedItems.length == 0}>{bundle.getMessage('delete')}</Button>
                <Button variant="primary" onClick={createBtnClickHandler}>{bundle.getMessage('create')}</Button>
              </SpaceBetween>
              </>
            }
          >
            Device Links
          </Header>
        }
        items={deviceLinkItems}
        loading={isTableLoading}
        loadingText={`${bundle.getMessage('loading')}`}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => handlePageSizeChange(detail.pageSize || pageSize)}
            title={bundle.getMessage('user-preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              pageSize: pageSize,
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25 }) },
                { value: 50, label: bundle.formatMessage('number-of-devices', { deviceCount: 50 }) },
                { value: 100, label: bundle.formatMessage('number-of-devices', { deviceCount: 100 }) },
                { value: 150, label: bundle.formatMessage('number-of-devices', { deviceCount: 150 }) },
                { value: 250, label: bundle.formatMessage('number-of-devices', { deviceCount: 250 }) },
                { value: 500, label: bundle.formatMessage('number-of-devices', { deviceCount: 500 }) }
              ],
            }}
          />
        }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType="multi"
        stickyHeader={false}
        trackBy="id"
      />
    </div>
    <Modal
      onDismiss={() => setDeleteVisible(false)}
      visible={deleteVisible}
      closeAriaLabel="Close"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={deleteNoBtnClickHandler}>{bundle.getMessage('no')}</Button>
            <Button variant="primary" onClick={deleteYesBtnClickHandler} loading={deleting}>{bundle.getMessage('yes')}</Button>
          </SpaceBetween>
        </Box>
      }
      header={bundle.getMessage('delete')}
    >
      {bundle.getMessage('confirm-delete')}
    </Modal>
    {
      showEditDeviceLinkPanel
      && selectedItems.length == 1
      && <DeviceLinkEditPanel
        acpDeviceOptions={props.acpDeviceOptions}
        acpDeviceOptionsLoading={props.acpDeviceOptionsLoading}
        cameraDeviceOptions={props.cameraDeviceOptions}
        cameraDeviceOptionsLoading={props.cameraDeviceOptionsLoading}
        cancelCallback={cancelUpdate}
        deviceLinks={props.deviceLinks}
        refreshDeviceOptions={props.refreshDeviceOptionsCallback}
        refreshCameraOptions={props.refreshCameraOptionsCallback}
        saveCallback={updatedDeviceLink}
        selectedDeviceLink={selectedItems[0]}
        systemOptions={props.systemOptions}
      />
    }
    {
      showCreateDeviceLinkPanel
      && <DeviceLinkCreatePanel
        acpDeviceOptions={props.acpDeviceOptions}
        acpDeviceOptionsLoading={props.acpDeviceOptionsLoading}
        cameraDeviceOptions={props.cameraDeviceOptions}
        cameraDeviceOptionsLoading={props.cameraDeviceOptionsLoading}
        cancelCreateCallback={cancelCreate}
        deviceLinks={props.deviceLinks}
        refreshDeviceOptions={props.refreshDeviceOptionsCallback}
        refreshCameraOptions={props.refreshCameraOptionsCallback}
        systemOptions={props.systemOptions}
        saveCallback={createdDeviceLinks}
      />
    }
    <br/>
    {
      !hideDeviceLinkEventsTable
      && deviceLinkEvents
      && selectedItems.length == 1
      &&
      <DeviceLinkEventsTablePanel
        deviceLinkId={selectedItems[0].id}
        deviceLinkEvents={deviceLinkEvents!}
        eventOptions={props.eventOptions}
        refreshCallback={refreshDeviceLinkEvents}
        isTableLoading={isDeviceLinkEventsTableLoading}
      />
    }
    </>
  );
}
