/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createSiteCameras = /* GraphQL */ `mutation CreateSiteCameras($input: CreateSiteCamerasInput!) {
  createSiteCameras(input: $input) {
    siteCode
    created
    cameras {
      name
      description
      systemIdentifier
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSiteCamerasMutationVariables,
  APITypes.CreateSiteCamerasMutation
>;
export const createSiteCamerasV1 = /* GraphQL */ `mutation CreateSiteCamerasV1($input: CreateSiteCamerasInput!) {
  createSiteCamerasV1(input: $input) {
    siteCode
    created
    cameras {
      name
      description
      systemIdentifier
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSiteCamerasV1MutationVariables,
  APITypes.CreateSiteCamerasV1Mutation
>;
export const createDeviceLink = /* GraphQL */ `mutation CreateDeviceLink($input: CreateDeviceLinkInput!) {
  createDeviceLink(input: $input) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceLinkMutationVariables,
  APITypes.CreateDeviceLinkMutation
>;
export const createDeviceLinkV1 = /* GraphQL */ `mutation CreateDeviceLinkV1($input: CreateDeviceLinkInput!) {
  createDeviceLinkV1(input: $input) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceLinkV1MutationVariables,
  APITypes.CreateDeviceLinkV1Mutation
>;
export const createDeviceLinkEvent = /* GraphQL */ `mutation CreateDeviceLinkEvent($input: CreateDeviceLinkEventInput!) {
  createDeviceLinkEvent(input: $input) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceLinkEventMutationVariables,
  APITypes.CreateDeviceLinkEventMutation
>;
export const createDeviceLinkEventV1 = /* GraphQL */ `mutation CreateDeviceLinkEventV1($input: CreateDeviceLinkEventInput!) {
  createDeviceLinkEventV1(input: $input) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDeviceLinkEventV1MutationVariables,
  APITypes.CreateDeviceLinkEventV1Mutation
>;
export const createSystem = /* GraphQL */ `mutation CreateSystem($input: CreateSystemInput!) {
  createSystem(input: $input) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemMutationVariables,
  APITypes.CreateSystemMutation
>;
export const createSystemV1 = /* GraphQL */ `mutation CreateSystemV1($input: CreateSystemInput!) {
  createSystemV1(input: $input) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSystemV1MutationVariables,
  APITypes.CreateSystemV1Mutation
>;
export const createUserAction = /* GraphQL */ `mutation CreateUserAction($input: UserActionInput!) {
  createUserAction(input: $input) {
    actionId
    actionName
    parameters
    timestamp
    ttl
    username
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActionMutationVariables,
  APITypes.CreateUserActionMutation
>;
export const deleteDeviceLink = /* GraphQL */ `mutation DeleteDeviceLink($input: DeleteDeviceLinkInput!) {
  deleteDeviceLink(input: $input) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceLinkMutationVariables,
  APITypes.DeleteDeviceLinkMutation
>;
export const deleteDeviceLinkV1 = /* GraphQL */ `mutation DeleteDeviceLinkV1($input: DeleteDeviceLinkInput!) {
  deleteDeviceLinkV1(input: $input) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceLinkV1MutationVariables,
  APITypes.DeleteDeviceLinkV1Mutation
>;
export const deleteDeviceLinkEvent = /* GraphQL */ `mutation DeleteDeviceLinkEvent($input: DeleteDeviceLinkEventInput!) {
  deleteDeviceLinkEvent(input: $input) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceLinkEventMutationVariables,
  APITypes.DeleteDeviceLinkEventMutation
>;
export const deleteDeviceLinkEventV1 = /* GraphQL */ `mutation DeleteDeviceLinkEventV1($input: DeleteDeviceLinkEventInput!) {
  deleteDeviceLinkEventV1(input: $input) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDeviceLinkEventV1MutationVariables,
  APITypes.DeleteDeviceLinkEventV1Mutation
>;
export const deleteSystem = /* GraphQL */ `mutation DeleteSystem($input: DeleteSystemInput!) {
  deleteSystem(input: $input) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemMutationVariables,
  APITypes.DeleteSystemMutation
>;
export const deleteSystemV1 = /* GraphQL */ `mutation DeleteSystemV1($input: DeleteSystemInput!) {
  deleteSystemV1(input: $input) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSystemV1MutationVariables,
  APITypes.DeleteSystemV1Mutation
>;
export const updateDeviceLink = /* GraphQL */ `mutation UpdateDeviceLink($input: UpdateDeviceLinkInput!) {
  updateDeviceLink(input: $input) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceLinkMutationVariables,
  APITypes.UpdateDeviceLinkMutation
>;
export const updateDeviceLinkV1 = /* GraphQL */ `mutation UpdateDeviceLinkV1($input: UpdateDeviceLinkInput!) {
  updateDeviceLinkV1(input: $input) {
    acd_device_name
    acd_device_type
    acd_parent_device_id
    acd_child_device_id
    acd_subchild_device_id
    camera_name
    camera_system_id
    created
    created_by
    enabled
    event_links
    id
    system_id
    system_name
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceLinkV1MutationVariables,
  APITypes.UpdateDeviceLinkV1Mutation
>;
export const updateDeviceLinkEvent = /* GraphQL */ `mutation UpdateDeviceLinkEvent($input: UpdateDeviceLinkEventInput!) {
  updateDeviceLinkEvent(input: $input) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceLinkEventMutationVariables,
  APITypes.UpdateDeviceLinkEventMutation
>;
export const updateDeviceLinkEventV1 = /* GraphQL */ `mutation UpdateDeviceLinkEventV1($input: UpdateDeviceLinkEventInput!) {
  updateDeviceLinkEventV1(input: $input) {
    created
    created_by
    device_link_id
    id
    event_name
    event_id
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDeviceLinkEventV1MutationVariables,
  APITypes.UpdateDeviceLinkEventV1Mutation
>;
export const updateSystem = /* GraphQL */ `mutation UpdateSystem($input: UpdateSystemInput!) {
  updateSystem(input: $input) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemMutationVariables,
  APITypes.UpdateSystemMutation
>;
export const updateSystemV1 = /* GraphQL */ `mutation UpdateSystemV1($input: UpdateSystemInput!) {
  updateSystemV1(input: $input) {
    auth_mode
    created
    created_by
    enabled
    id
    name
    secondary_system_id
    secondary_system_name
    segment_name
    segment_location
    segment_source
    srvr_alrm_srvc_path
    srvr_alrm_srvc_port
    srvr_cmd_srvc_path
    srvr_cmd_srvc_port
    srvr_cnf_srvc_path
    srvr_cnf_srvc_port
    srvr_vpc_endpoint
    updated
    updated_by
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSystemV1MutationVariables,
  APITypes.UpdateSystemV1Mutation
>;
