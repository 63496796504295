import { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';

const appLayoutNavigationLabels: Partial<AppLayoutProps.Labels> = {
    navigationClose: 'Close navigation',
    navigationToggle: 'Toggle navigation'
};

const appLayoutToolsLabels: Partial<AppLayoutProps.Labels> = {
    tools: 'Tool bar',
    toolsClose: 'Close tool bar',
    toolsToggle: 'Open tool bar'
};

const appLayoutLabels: AppLayoutProps.Labels = { ...appLayoutNavigationLabels, ...appLayoutToolsLabels };

export { appLayoutNavigationLabels, appLayoutToolsLabels, appLayoutLabels };