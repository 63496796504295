import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Form,
  FormField,
  Input,
  Select,
  SpaceBetween
} from '@amzn/awsui-components-react';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { createSystemV1 as createSystemMutation } from "../../graphql/mutations";
import { CreatedSystemInterface } from './TablePanel';
import { CancelCreateSystemInterface } from './TablePanel';
import { CreateSystemV1Mutation } from 'src/API';
import { useHookstate } from '@hookstate/core';
import { forceAwakensBaseState } from 'src/stores/app';
import { SelectOptionInterface } from '../DeviceLinks/MainContents';
import { AUTHENTICATION_MODES, UserActionNames } from 'src/constants/Constants';
import { createUserAction } from 'src/utils/UserActionsUtils';

export interface SystemCreatePanelPropsInterface {
  cancelCreateCallback: CancelCreateSystemInterface;
  saveCallback: CreatedSystemInterface;
  secondarySystemOptions: SelectOptionInterface[];
}

export const SystemCreatePanel = (props: SystemCreatePanelPropsInterface) => {

  const [authenticationValue, setAuthenticationValue] = useState<string>('');
  const [nameValue, setNameValue] = useState<string>();
  const [segmentNameValue, setSegmentNameValue] = useState<string>();
  const [enabledValue, setEnabledValue] = useState<string>('N');
  const [serverVPCEndpointValue, setServerVPCEndpointValue] = useState<string>();
  const [serverCommandServicePathValue, setServerCommandServicePathValue] = useState<string>();
  const [serverCommandServicePortValue, setServerCommandServicePortValue] = useState<string>();
  const [serverConfigurationServicePathValue, setServerConfigurationServicePathValue] = useState<string>();
  const [serverConfigurationServicePortValue, setServerConfigurationServicePortValue] = useState<string>();
  const [serverAlarmServicePathValue, setServerAlarmServicePathValue] = useState<string>();
  const [serverAlarmServicePortValue, setServerAlarmServicePortValue] = useState<string>();
  const [secondarySystemIdValue, setSecondarySystemIdValue] = useState<string>('0');
  const [secondarySystemNameValue, setSecondarySystemNameValue] = useState<string>('None');

  const forceAwakensState = useHookstate(forceAwakensBaseState);

  const createSystem = async () => {
    try {
      const response = await API.graphql(graphqlOperation(createSystemMutation,
        {
          input:
            {
              auth_mode: authenticationValue,
              created_by: forceAwakensState.username.value,
              enabled: enabledValue,
              name: nameValue,
              secondary_system_id: secondarySystemIdValue,
              segment_location: forceAwakensState.value.selectedSite?.SegmentLocation,
              segment_name: forceAwakensState.value.selectedSite?.siteCode,
              segment_source: forceAwakensState.value.selectedSite?.SegmentSource,
              srvr_vpc_endpoint: serverVPCEndpointValue,
              srvr_cnf_srvc_path: serverConfigurationServicePathValue,
              srvr_cnf_srvc_port: serverConfigurationServicePortValue,
              srvr_cmd_srvc_path: serverCommandServicePathValue,
              srvr_cmd_srvc_port: serverCommandServicePortValue,
              srvr_alrm_srvc_path: serverAlarmServicePathValue,
              srvr_alrm_srvc_port: serverAlarmServicePortValue,
            }
        })) as GraphQLResult<CreateSystemV1Mutation>;
      if (response && response.data && response.data.createSystemV1) {
        createUserAction(
          {
            actionName: UserActionNames.CreateSystem,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                systemCreate: response.data.createSystemV1,
              })
          });
        const createdSystem = response.data.createSystemV1;
        props.saveCallback(createdSystem);
      }
    } catch (e) {
      console.log(`createSystem(): exception is ${JSON.stringify(e)}`);
      createUserAction(
        {
          actionName: UserActionNames.CreateSystemError,
          username: forceAwakensState.username.value,
          parameters: JSON.stringify(
            {
              systemCreateError: e,
            })
        });
    }
  }

  const cancelBtnHandler = () => {
    props.cancelCreateCallback();
  }

  const saveBtnHandler = () => {
    if (nameValue) createSystem();
  }

  const authenticationFieldChangeHandler = (detail: any) => {
    setAuthenticationValue(detail.selectedOption.label);
  };

  const systemNameFieldOnChangeHandler = (detail: any) => {
    setNameValue((prevValue) => {return detail.value});
  };

  const segmentNameFieldOnChangeHandler = (detail: any) => {
    setSegmentNameValue(detail.value);
  }

  const secondarySystemFieldChangeHandler = (detail: any) => {
    setSecondarySystemNameValue(detail.selectedOption.label);
    setSecondarySystemIdValue(detail.selectedOption.value);
  };

  const systemEnabledFieldOnChangeHandler = (detail: any) => {
    detail.checked ? setEnabledValue('Y') : setEnabledValue('N');
  };

  return (
    <div>
      <Container>
        <Form>
          <SpaceBetween size="xs" direction="vertical">
            <FormField description="A name for the system." label="Name">
              <Input
                id="SystemNameInputField"
                onChange={({ detail }) => systemNameFieldOnChangeHandler(detail)}
                value={nameValue!}
                placeholder="Enter Name"
                inputMode="text"
                autoFocus
              />
            </FormField>
            <FormField description="Site Name." label="Site">
              <Input
                id="SegmentNameInputField"
                onChange={({ detail }) => segmentNameFieldOnChangeHandler(detail)}
                value={forceAwakensState.value.selectedSite?.siteCode!}
                inputMode="text"
                readOnly
              />
            </FormField>
            <FormField description="Server VPC Endpoint." label="Server VPC Endpoint">
              <Input
                id="ServerVPCEndpoint"
                onChange={({ detail }) => setServerVPCEndpointValue(detail.value)}
                value={serverVPCEndpointValue!}
                placeholder="Enter VPC Endpoint"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Configuration Service Path." label="Server Configuration Service Path">
              <Input
                id="serverConfigurationServicePath"
                onChange={({ detail }) => setServerConfigurationServicePathValue(detail.value)}
                value={serverConfigurationServicePathValue!}
                placeholder="Enter Server Configration Service Path"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Configuration Service Port." label="Server Configuration Service Port">
              <Input
                id="serverConfigurationServicePort"
                onChange={({ detail }) => setServerConfigurationServicePortValue(detail.value)}
                value={serverConfigurationServicePortValue!}
                placeholder="Enter Server Configration Service Port"
                inputMode="numeric"
              />
            </FormField>
            <FormField description="Server Command Service Path." label="Server Command Service Path">
              <Input
                id="serverCommandServicePath"
                onChange={({ detail }) => setServerCommandServicePathValue(detail.value)}
                value={serverCommandServicePathValue!}
                placeholder="Enter Server Command Service Path"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Command Service Port." label="Server Command Service Port">
              <Input
                id="serverCommandServicePort"
                onChange={({ detail }) => setServerCommandServicePortValue(detail.value)}
                value={serverCommandServicePortValue!}
                placeholder="Enter Server Command Service Port"
                inputMode="numeric"
              />
            </FormField>
            <FormField description="Server Alarm Service Path." label="Server Alarm Service Path">
              <Input
                id="serverAlarmServicePath"
                onChange={({ detail }) => setServerAlarmServicePathValue(detail.value)}
                value={serverAlarmServicePathValue!}
                placeholder="Enter Server Alarm Service Path"
                inputMode="text"
              />
            </FormField>
            <FormField description="Server Alarm Service Port." label="Server Alarm Service Port">
              <Input
                id="serverAlarmServicePort"
                onChange={({ detail }) => setServerAlarmServicePortValue(detail.value)}
                value={serverAlarmServicePortValue!}
                placeholder="Enter Server Alarm Service Port"
                inputMode="numeric"
              />
            </FormField>
            <FormField description="Authentication" label="Authentication">
              <Select
                id="Authentication"
                filteringType='auto'
                onChange={({ detail }) => authenticationFieldChangeHandler(detail)}
                options={AUTHENTICATION_MODES}
                selectedAriaLabel="Selected"
                selectedOption={{ label: authenticationValue, value: authenticationValue }}
              />
            </FormField>
            <FormField description="Secondary System." label="Secondary System">
              <Select
                id="SecondarySystem"
                filteringType='auto'
                onChange={({ detail }) => secondarySystemFieldChangeHandler(detail)}
                options={props.secondarySystemOptions}
                selectedAriaLabel="Selected"
                selectedOption={{ label: secondarySystemNameValue, value: secondarySystemIdValue }}
              />
            </FormField>
            <FormField description="Enable or disable system." label="Enabled">
              <Checkbox
                id="SystemEnabledCheckbox"
                onChange={({ detail }) => systemEnabledFieldOnChangeHandler(detail)}
                checked={enabledValue === 'Y'}
              >
                Enabled
              </Checkbox>
            </FormField>
            <SpaceBetween size="xs" direction="horizontal">
              <Button onClick={cancelBtnHandler}>Cancel</Button>
              <Button onClick={saveBtnHandler} variant="primary">Save</Button>
            </SpaceBetween>
          </SpaceBetween>
        </Form>
      </Container>
    </div>
  );
}
