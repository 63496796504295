import React from 'react';
import AppLayout, { AppLayoutProps } from '@amzn/awsui-components-react/polaris/app-layout';
import { ForceAwakensConfigNavigation } from '../navigation';
import DeviceLinksMainContent from './MainContents';
import { appLayoutLabels } from '../../common/labels';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react';
import { ForceAwakensStateInterface } from 'src/stores/app';
import { IsItDown } from '../common/IsItDown';
import { State } from '@hookstate/core';

export class DeviceLinks extends React.Component<{forceAwakensState: State<ForceAwakensStateInterface>}> {

  state = {
    navigationOpen: true
  };

  onNavigationChange(event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) {
    this.setState({
      navigationOpen: event.detail.open
    });
  }

  render() {
    return (
      <div id='DeviceLinks'>
        <AppLayout
          ariaLabels={appLayoutLabels}
          content={<DeviceLinksMainContent forceAwakensState={this.props.forceAwakensState} />}
          contentType='table'
          disableBodyScroll
          disableContentPaddings
          headerSelector='#topNavigation'
          navigation={<ForceAwakensConfigNavigation forceAwakensState={this.props.forceAwakensState}/>}
          navigationOpen={this.state.navigationOpen}
          notifications={<IsItDown />}
          onNavigationChange={event => this.onNavigationChange(event)}
          stickyNotifications
          toolsHide
        />
      </div>
    );
  }

}

export default DeviceLinks;