import React from 'react';
import ReactDOM from 'react-dom';
import AuthenticateUser from './components/AuthenticateUser';
import AppInitWrapper from './components/AppInitWrapper';
import './i18n';
const arbManifest = require('../build/i18n/arbManifest');
import { DEFAULT_LC_BUILDER, DEFAULT_LOCALE } from './constants/Constants';
import '@amzn/katal-components/styles.css';
import './index.scss';
import { Spinner } from '@amzn/awsui-components-react';
import { MbmProvider } from '@amzn/react-arb-tools';
import { Amplify, Auth } from 'aws-amplify';

if ('beta'.toLowerCase() !== 'prod') {
  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

const logger = new Amplify.Logger('test');
logger.log(`index.tsx`);
console.debug('Stage is ' + 'beta');
console.debug('APPSYNC_API_DOMAIN is ' + 'api.beta.force-awakens.gso.amazon.dev');
console.debug('AWS_REGION is ' + 'us-east-1');
console.debug('IDENTITY_POOL_DOMAIN is ' + 'gso-forceawakens-beta.auth.us-east-1.amazoncognito.com');
console.debug('IDENTITY_POOL_ID is ' + 'us-east-1:c2c4ea34-2e33-417f-a4be-3420428a6597');
console.debug('USER_POOL_ID is ' + 'us-east-1_yvaN5oc84');

const callbackUrl = window.location.origin;
const config = {
  oauth: {
    domain: 'gso-forceawakens-beta.auth.us-east-1.amazoncognito.com',
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code',
  },
  // Cognito
  aws_cognito_identity_pool_id: 'us-east-1:c2c4ea34-2e33-417f-a4be-3420428a6597',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_yvaN5oc84',
  aws_user_pools_web_client_id: 'jgtehvu1114do7lc4cj7ehac6',
  // AppSync
  aws_appsync_graphqlEndpoint: 'https://api.beta.force-awakens.gso.amazon.dev/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  API: {
    graphql_endpoint: 'https://api.beta.force-awakens.gso.amazon.dev/graphql',
    graphql_headers: async () => ({
      Authorization: (await Auth.currentAuthenticatedUser())
        .getSignInUserSession()
        .getIdToken()
        .getJwtToken(),
    }),
  },
};
Amplify.configure(config);

const mbmOptions = {
  arbManifest,
  defaultLocalizationContext: DEFAULT_LC_BUILDER.withLocale(DEFAULT_LOCALE).withDefaultLocale(DEFAULT_LOCALE).build(),
  urlPrefix: '/i18n',
};

ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<Spinner size='large' />}>
        <MbmProvider {...mbmOptions}>
          <AuthenticateUser />
        </MbmProvider>
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);