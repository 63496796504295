import React, { useEffect, useState } from 'react';
import { State } from '@hookstate/core';
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import App from './App';
import { ForceAwakensStateInterface } from 'src/stores/app';

export default function AuthorizeUser(props: {forceAwakensState: State<ForceAwakensStateInterface>}) {

  const [hasPermissionValue, setHasPermissionValue] = useState<boolean>();

  let userHasPermission = false;

  useEffect(() => {
    const username = props.forceAwakensState.username.value;
    if (username) {
      const getHasPermission = async () => {
        // all authenticated users have permission
        userHasPermission = true;
        setHasPermissionValue(userHasPermission);
      };
      getHasPermission();
    }
  }, [props.forceAwakensState.username.value]);

  let componentToRender;

  if (hasPermissionValue) {
    componentToRender = (
      <>
        <App forceAwakensState={props.forceAwakensState}/>
      </>
    )
  } else if (hasPermissionValue === undefined) {
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s"}}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        Checking permissions for <strong>{props.forceAwakensState.username.value}</strong>...
        If this page does not refresh automatically after a brief pause please contact
        <Link external href="https://w.amazon.com/index.php/SIDE#HContactus"> SIDE Support</Link>
      </Box>
    )
  } else {
    componentToRender = (
      <Box
        margin={{ top: "s", left: "s"}}
        color="inherit"
        display="block"
        fontSize="heading-l"
      >
        <strong>{props.forceAwakensState.username.value}</strong> does not have permission to access ForceAwakens Configuration,
        if you believe this is incorrect please contact
        <Link external href="https://w.amazon.com/index.php/SIDE#HContactus"> SIDE Support</Link>
      </Box>
    )
  }

  return componentToRender;
}
