import React from 'react';
import { JsonTable } from 'react-json-to-html';
import { Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { Audit } from '../../API';

export interface AuditActionPropsInterface {
  selectedAudit: Audit;
}

export const AuditAction = (props: AuditActionPropsInterface) => {

  const rowData = JSON.parse(props.selectedAudit.row_data_json!);
  const changedFields = JSON.parse(props.selectedAudit.changed_fields_json!);

  const font = 'Amazon Ember';

  const css = {
    jsonTr: {
      height: '25px'
    },
    jsonTd: {
      padding: '5px',
      borderSpacing: '2px',
      borderRadius: '5px'
    },
    rowSpacer: {
      height: '2px'
    },
    rootElement: {
      padding: '5px',
      borderSpacing: '2px',
      backgroundColor: 'white',
      fontWeight: 'bold',
      fontFamily: font,
      fontSize: '14px',
      borderRadius: '0px',
      borderColor: 'black'
    },
    dataCell: {
      borderSpacing: '2px',
      backgroundColor: 'white',
      fontFamily: font,
      borderRadius: '5px',
      borderColor: 'black'
    }
  }

  return (
    <div key={props.selectedAudit.event_id}>
      <SpaceBetween size="xs" direction="horizontal">
        <Container
          header={
            <Header
              variant="h2"
            >
              Prior Row Data
            </Header>
          }
        >
          {rowData && <JsonTable json={rowData} css={css} />}
        </Container>
        <Container
          header={
            <Header
              variant="h2"
            >
              Changed Fields
            </Header>
          }
        >
          {changedFields && <JsonTable json={changedFields} css={css} />}
        </Container>
      </SpaceBetween>
    </div>
  );
}