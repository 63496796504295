import { createState } from '@hookstate/core';

export interface ForceAwakensStateInterface {
  darkMode: boolean;
  username: string | undefined;
  isAdmin: boolean | undefined;
  selectedRegion: string | undefined;
  selectedSite: {
    SegmentLocation: string;
    SegmentName: string;
    SegmentSource: string;
    siteCode: string;
  } | undefined,
}

export const forceAwakensBaseState = createState<ForceAwakensStateInterface>({
  darkMode: false,
  username: undefined,
  isAdmin: undefined,
  selectedRegion: undefined,
  selectedSite: undefined
});