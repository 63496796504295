import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';

import * as APIt from "../../API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useBundle } from '@amzn/react-arb-tools';
import { Spinner } from '@amzn/awsui-components-react';

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.DeviceLinkEvent>[] = [
  {
    id: 'event',
    header: <TranslateHeader value={'event'} />,
    cell: item => item.event_name,
    minWidth: 250,
    sortingField: 'event_name',
  },
  {
    id: 'created',
    header: <TranslateHeader value={'created'} />,
    cell: item => item.created,
    minWidth: 150,
    sortingField: 'created',
  },
  {
    id: 'updated',
    header: <TranslateHeader value={'updated'} />,
    cell: item => item.updated,
    minWidth: 150,
    sortingField: 'updated',
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.childTableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
