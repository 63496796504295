import React from 'react';
import Box from '@amzn/awsui-components-react/polaris/box';
import Toggle from '@amzn/awsui-components-react/polaris/toggle';
import { TableProps } from '@amzn/awsui-components-react/polaris/table';

import * as APIt from "../../API";
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useBundle } from '@amzn/react-arb-tools';
import { Spinner } from '@amzn/awsui-components-react';

export const ColumnDefinitions: TableProps.ColumnDefinition<APIt.System>[] = [
  {
    id: 'name',
    header: <TranslateHeader value={'system-name'}/>,
    cell: item => item.name,
    minWidth: 150
  },
  {
    id: 'enabled_desc',
    header: <TranslateHeader value={'enabled'}/>,
    cell: (item) => <Toggle checked={item.enabled === 'Y'} onChange={()=>{}}/>
  },
  {
    id: 'segment_name',
    header: <TranslateHeader value={'site-name'}/>,
    cell: item => item.segment_name,
    minWidth: 150
  },
  {
    id: 'srvr_vpc_endpoint',
    header: <TranslateHeader value={'srvr-vpc-endpoint'}/>,
    cell: item => item.srvr_vpc_endpoint,
    minWidth: 150
  },
  {
    id: 'srvr_cnf_srvc_path',
    header: <TranslateHeader value={'srvr-config-svc-path'}/>,
    cell: item => item.srvr_cnf_srvc_path,
    minWidth: 150
  },
  {
    id: 'srvr_cnf_srvc_port',
    header: <TranslateHeader value={'srvr-config-svc-port'}/>,
    cell: item => item.srvr_cnf_srvc_port,
    minWidth: 150
  },
  {
    id: 'srvr_cmd_srvc_path',
    header: <TranslateHeader value={'srvr-cmd-svc-path'}/>,
    cell: item => item.srvr_cmd_srvc_path,
    minWidth: 150
  },
  {
    id: 'srvr_cmd_srvc_port',
    header: <TranslateHeader value={'srvr-cmd-svc-port'}/>,
    cell: item => item.srvr_cmd_srvc_port,
    minWidth: 150
  },
  {
    id: 'srvr_alrm_srvc_path',
    header: <TranslateHeader value={'srvr-alrm-svc-path'}/>,
    cell: item => item.srvr_alrm_srvc_path,
    minWidth: 150
  },
  {
    id: 'srvr_alrm_srvc_port',
    header: <TranslateHeader value={'srvr-alrm-svc-port'}/>,
    cell: item => item.srvr_alrm_srvc_port,
    minWidth: 150
  },
  {
    id: 'auth_mode',
    header: <TranslateHeader value={'auth-mode'}/>,
    cell: item => item.auth_mode,
  },
  {
    id: 'secondary_system_name',
    header: <TranslateHeader value={'secondary-system'}/>,
    cell: item => item.secondary_system_name,
    minWidth: 150
  },
  {
    id: 'created',
    header: <TranslateHeader value={'created'}/>,
    cell: item => item.created,
    minWidth: 150
  },
  {
    id: 'updated',
    header: <TranslateHeader value={'updated'}/>,
    cell: item => item.updated,
    minWidth: 150
  },
];

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
    <SpaceBetween size="xxs">
      <div>
        <b>No matches</b>
        <Box variant="p" color="inherit">
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Systems.tableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

export const DefaultPageSize = {
  pageSize: 100
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
