import React, { useState, useEffect } from 'react';
import { Box, Button, DatePicker, CollectionPreferences, DateRangePicker, DateRangePickerProps, Form, FormField, Grid, Modal, Pagination, SpaceBetween, Table, TextFilter, Spinner } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './table-config';
import * as APIt from "../../API";
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { listAuditV1 } from 'src/graphql/queries';
import { AuditAction } from './AuditAction';
import { UserActionNames } from 'src/constants/Constants';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { forceAwakensBaseState } from 'src/stores/app';
import { fetchPageSize } from 'src/utils/UserPreferences';
import { useBundle } from '@amzn/react-arb-tools';

export interface CancelCreateSystemInterface {
  (): void;
}
export interface CreatedSystemInterface {
  (createdSystem: APIt.System): void;
}
export interface CancelUpdateSystemInterface {
  (): void;
}
export interface UpdatedAuditnterface {
  (updatedSystem: APIt.System): void;
}
export interface AuditTablePanelPropsInterface {
}

export default function AuditTablePanel(props: AuditTablePanelPropsInterface ) {

  const [allAudits, setAllAudits] = useState<APIt.Audit[]>([]);
  const [bundle, isBundleLoading] = useBundle('components.Audit.TablePanel');
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [endDatePicker, setEndDatePicker] = useState<string>('');
  const [hideAuditAction, setHideAuditAction] = useState<boolean>(true);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [selectedAudits, setSelectedAudits] = useState<APIt.Audit[]>();
  const [selectedAuditAction, setSelectedAuditAction] = useState<APIt.Audit>();
  const [startDatePicker, setStartDatePicker] = useState<string>('');
  const [datesRequiredVisible, setDatesRequiredVisible] = useState<boolean>(false);
  const [pageSize, handlePageSizeChange] = fetchPageSize('Audit', 25);

  useEffect(() => {
    if (selectedAudits && selectedAudits.length > 0) {
      setSelectedAuditAction(selectedAudits[0]);
      setHideAuditAction(false);
    } else {
      setHideAuditAction(true);
    }
  }, [selectedAudits]);

  useEffect(() => {
    setIsMounted(true);
    return function cleanup() {
      setIsMounted(false);
    };
  }, []);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allAudits,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? "No Audit Data Found" : bundle.getMessage('no-audit-data')} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: "event_id" }
    }
  );

  const refreshAuditData = async (): Promise<void> => {
    if (!isMounted) return;
    if (startDatePicker === '' || endDatePicker === '') {
      setDatesRequiredVisible(true);
      return;
    }
    setAllAudits([]);
    setIsTableLoading(true);
    const limit = 500;
    let offset = 0, audits: APIt.Audit[] = [];
    while (true) {
      const response = await API.graphql(graphqlOperation(listAuditV1, {
        limit,
        offset,
        endDate: endDatePicker,
        startDate: startDatePicker,
      })) as GraphQLResult<APIt.ListAuditV1Query>;
      if (response.data?.listAuditV1?.length == 0) break;
      audits.push(...(response.data?.listAuditV1 as APIt.Audit[]));
      createUserAction({
        actionName: UserActionNames.AuditDataSearch,
        username: forceAwakensBaseState.username.value,
        parameters: JSON.stringify(
          {
            startDate: startDatePicker,
            endDate: endDatePicker,
            item_count: response.data?.listAuditV1?.length
          }
        )
      });
      offset += limit;
    }
    if (isMounted) {
      setAllAudits(audits);
      setIsTableLoading(false);
    }
  }

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const [hideTable, setHideTable] = useState<boolean>(false);

  const itemsCount = (): number => {
    if (allAudits) return allAudits.length;
    return 0;
  }

  if (isBundleLoading) return <Spinner/>;
  
  return(
    <>
    <div id="tableDiv" hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Filter Audit Data"
            filteringPlaceholder={isBundleLoading ? "Find Audit Data" : bundle.getMessage('find-audit')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <Form>
              <SpaceBetween direction='horizontal' size={'s'}>
                <FormField
                  label={isBundleLoading ? 'Start Date' : bundle.getMessage('start-date')}
                >
                  <DatePicker
                    autoFocus
                    onChange={({ detail }) => setStartDatePicker(detail.value)}
                    value={startDatePicker}
                    openCalendarAriaLabel={selectedDate =>
                      "Choose Date" +
                      (selectedDate
                        ? `, selected date is ${selectedDate}`
                        : "")
                    }
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                  />
                </FormField>
                <FormField
                  label={isBundleLoading ? 'End Date' : bundle.getMessage('end-date')}
                >
                  <DatePicker
                    onChange={({ detail }) => setEndDatePicker(detail.value)}
                    value={endDatePicker}
                    openCalendarAriaLabel={selectedDate =>
                      "Choose Date" +
                      (selectedDate
                        ? `, selected date is ${selectedDate}`
                        : "")
                    }
                    nextMonthAriaLabel="Next month"
                    placeholder="YYYY/MM/DD"
                    previousMonthAriaLabel="Previous month"
                    todayAriaLabel="Today"
                  />
                </FormField>
                <FormField label='&#8203;'>
                  <Button
                    onClick={refreshAuditData}
                    variant="primary"
                  >
                    Search
                  </Button>
                </FormField>
              </SpaceBetween>
              </Form>
              </>
            }
          >
            Audit Data
          </Header>
        }
        items={items}
        loading={isTableLoading}
        loadingText={isBundleLoading ? "Loading audit data, please wait..." : bundle.getMessage('loading')}
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) => handlePageSizeChange(detail.pageSize || pageSize)}
            title={bundle.getMessage('user-preferences')}
            confirmLabel={bundle.getMessage('confirm')}
            cancelLabel={bundle.getMessage('cancel')}
            preferences={{
              pageSize: pageSize,
            }}
            pageSizePreference={{
              title: bundle.getMessage('select-page-size'),
              options: [
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
                { value: 150, label: '150' },
                { value: 250, label: '250' },
                { value: 500, label: '500' }
              ],
            }}
          />
        }
        resizableColumns={true}
        stickyHeader={false}
        onSelectionChange={({ detail }) => setSelectedAudits(detail.selectedItems) }
        selectedItems={selectedAudits}
        selectionType="single"
        trackBy="event_id"
      />
    </div>
    <br/>
    <div id="AuditActionDiv" hidden={hideAuditAction || !selectedAuditAction}>
      { (selectedAuditAction) ? <AuditAction selectedAudit={selectedAuditAction} /> : <></> }
    </div>
    <Modal
      onDismiss={() => setDatesRequiredVisible(false)}
      visible={datesRequiredVisible}
      closeAriaLabel="Close"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={ () => setDatesRequiredVisible(false) } >
            {isBundleLoading ? "Ok" : bundle.getMessage('ok')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={isBundleLoading ? "Date Range Required" : bundle.getMessage('date-range-required') }
    >
      {isBundleLoading ? "Enter a start and end date to perform a search." : bundle.getMessage('date-required-message')}
    </Modal>
    </>
  );
}
