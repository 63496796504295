/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateSiteCamerasInput = {
  siteCode: string,
  created: string,
  cameras: Array< CameraInput | null >,
};

export type CameraInput = {
  name: string,
  description?: string | null,
  systemIdentifier: string,
};

export type SiteCameras = {
  __typename: "SiteCameras",
  siteCode: string,
  created: string,
  cameras:  Array<Camera | null >,
};

export type Camera = {
  __typename: "Camera",
  name: string,
  description?: string | null,
  systemIdentifier: string,
};

export type CreateDeviceLinkInput = {
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  created_by: string,
  enabled: string,
  system_id: string,
};

export type DeviceLink = {
  __typename: "DeviceLink",
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  created: string,
  created_by: string,
  enabled: string,
  event_links?: Array< string | null > | null,
  id: string,
  system_id: string,
  system_name: string,
  updated: string,
  updated_by: string,
};

export type CreateDeviceLinkEventInput = {
  created_by: string,
  device_link_id: string,
  event_id: string,
};

export type DeviceLinkEvent = {
  __typename: "DeviceLinkEvent",
  created: string,
  created_by: string,
  device_link_id: string,
  id: string,
  event_name: string,
  event_id: string,
  updated: string,
  updated_by: string,
};

export type CreateSystemInput = {
  auth_mode?: AuthMode | null,
  created_by: string,
  enabled: string,
  name: string,
  secondary_system_id?: string | null,
  segment_location: string,
  segment_name: string,
  segment_source: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
};

export enum AuthMode {
  BASIC = "BASIC",
  NTLM = "NTLM",
  NTLM_OR_BASIC = "NTLM_OR_BASIC",
}


export type System = {
  __typename: "System",
  auth_mode: AuthMode,
  created: string,
  created_by: string,
  enabled: string,
  id: string,
  name: string,
  secondary_system_id?: string | null,
  secondary_system_name?: string | null,
  segment_name: string,
  segment_location: string,
  segment_source: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
  updated: string,
  updated_by: string,
};

export type UserActionInput = {
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type UserAction = {
  __typename: "UserAction",
  actionId: string,
  actionName: string,
  parameters?: string | null,
  timestamp: string,
  ttl: number,
  username: string,
};

export type DeleteDeviceLinkInput = {
  id: string,
  updated_by: string,
};

export type DeleteDeviceLinkEventInput = {
  id: string,
  updated_by: string,
};

export type DeleteSystemInput = {
  id: string,
  updated_by: string,
};

export type UpdateDeviceLinkInput = {
  acd_device_name: string,
  acd_device_type: string,
  acd_parent_device_id: number,
  acd_child_device_id: number,
  acd_subchild_device_id: number,
  camera_name: string,
  camera_system_id: string,
  id: string,
  system_id: string,
  enabled: string,
  updated_by: string,
};

export type UpdateDeviceLinkEventInput = {
  event_id: string,
  id: string,
  updated_by: string,
};

export type UpdateSystemInput = {
  auth_mode?: AuthMode | null,
  enabled: string,
  id: string,
  name: string,
  segment_name: string,
  segment_location: string,
  segment_source: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
  secondary_system_id?: string | null,
  updated_by: string,
};

export type Event = {
  __typename: "Event",
  created: string,
  created_by: string,
  device_type: string,
  id: string,
  name: string,
  updated: string,
  updated_by: string,
};

export type EventLinks = {
  __typename: "EventLinks",
  event_links?:  Array<EventLink | null > | null,
};

export type EventLink = {
  __typename: "EventLink",
  device_link_id?: string | null,
  devices?: EventLinkDevices | null,
  events?: Array< string | null > | null,
  system?: SystemForEventLink | null,
};

export type EventLinkDevices = {
  __typename: "EventLinkDevices",
  cameraName: string,
  cameraSystemIdentifier: string,
  accessControlDeviceName: string,
  accessControlDeviceParentDeviceID: number,
  accessControlDeviceChildDeviceID: number,
  accessControlDeviceSubchildDeviceID: number,
  accessControlDeviceType: string,
};

export type SystemForEventLink = {
  __typename: "SystemForEventLink",
  auth_mode: AuthMode,
  system_id: string,
  secondary_system_id?: string | null,
  facman_sitename: string,
  srvr_alrm_srvc_path: string,
  srvr_alrm_srvc_port: string,
  srvr_cmd_srvc_path: string,
  srvr_cmd_srvc_port: string,
  srvr_cnf_srvc_path: string,
  srvr_cnf_srvc_port: string,
  srvr_vpc_endpoint: string,
};

export type AccessControlDevice = {
  __typename: "AccessControlDevice",
  Parent_DeviceID: number,
  Child_DeviceID: number,
  Subchild_DeviceID: number,
  Device_Type: string,
  DeviceName: string,
};

export type Audit = {
  __typename: "Audit",
  event_id: string,
  table_name: string,
  action_tstamp_tx: string,
  action: string,
  row_data?: string | null,
  row_data_json?: string | null,
  changed_fields?: string | null,
  changed_fields_json?: string | null,
};

export type Permission = {
  __typename: "Permission",
  HASPERMISSION: string,
};

export type Site = {
  __typename: "Site",
  SegmentName: string,
  SegmentLocation: string,
  SegmentSource: string,
  SiteCode: string,
};

export type SIGInfraSite = {
  __typename: "SIGInfraSite",
  allSites?: boolean | null,
  sites?: Array< string | null > | null,
};

export type CreateSiteCamerasMutationVariables = {
  input: CreateSiteCamerasInput,
};

export type CreateSiteCamerasMutation = {
  createSiteCameras?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type CreateSiteCamerasV1MutationVariables = {
  input: CreateSiteCamerasInput,
};

export type CreateSiteCamerasV1Mutation = {
  createSiteCamerasV1?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type CreateDeviceLinkMutationVariables = {
  input: CreateDeviceLinkInput,
};

export type CreateDeviceLinkMutation = {
  createDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkV1MutationVariables = {
  input: CreateDeviceLinkInput,
};

export type CreateDeviceLinkV1Mutation = {
  createDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkEventMutationVariables = {
  input: CreateDeviceLinkEventInput,
};

export type CreateDeviceLinkEventMutation = {
  createDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateDeviceLinkEventV1MutationVariables = {
  input: CreateDeviceLinkEventInput,
};

export type CreateDeviceLinkEventV1Mutation = {
  createDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateSystemMutationVariables = {
  input: CreateSystemInput,
};

export type CreateSystemMutation = {
  createSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateSystemV1MutationVariables = {
  input: CreateSystemInput,
};

export type CreateSystemV1Mutation = {
  createSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: UserActionInput,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    __typename: "UserAction",
    actionId: string,
    actionName: string,
    parameters?: string | null,
    timestamp: string,
    ttl: number,
    username: string,
  } | null,
};

export type DeleteDeviceLinkMutationVariables = {
  input: DeleteDeviceLinkInput,
};

export type DeleteDeviceLinkMutation = {
  deleteDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkV1MutationVariables = {
  input: DeleteDeviceLinkInput,
};

export type DeleteDeviceLinkV1Mutation = {
  deleteDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkEventMutationVariables = {
  input: DeleteDeviceLinkEventInput,
};

export type DeleteDeviceLinkEventMutation = {
  deleteDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteDeviceLinkEventV1MutationVariables = {
  input: DeleteDeviceLinkEventInput,
};

export type DeleteDeviceLinkEventV1Mutation = {
  deleteDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteSystemMutationVariables = {
  input: DeleteSystemInput,
};

export type DeleteSystemMutation = {
  deleteSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type DeleteSystemV1MutationVariables = {
  input: DeleteSystemInput,
};

export type DeleteSystemV1Mutation = {
  deleteSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkMutationVariables = {
  input: UpdateDeviceLinkInput,
};

export type UpdateDeviceLinkMutation = {
  updateDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkV1MutationVariables = {
  input: UpdateDeviceLinkInput,
};

export type UpdateDeviceLinkV1Mutation = {
  updateDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkEventMutationVariables = {
  input: UpdateDeviceLinkEventInput,
};

export type UpdateDeviceLinkEventMutation = {
  updateDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateDeviceLinkEventV1MutationVariables = {
  input: UpdateDeviceLinkEventInput,
};

export type UpdateDeviceLinkEventV1Mutation = {
  updateDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateSystemMutationVariables = {
  input: UpdateSystemInput,
};

export type UpdateSystemMutation = {
  updateSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type UpdateSystemV1MutationVariables = {
  input: UpdateSystemInput,
};

export type UpdateSystemV1Mutation = {
  updateSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetCachedSiteCamerasQueryVariables = {
  siteCode: string,
};

export type GetCachedSiteCamerasQuery = {
  getCachedSiteCameras?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type GetCachedSiteCamerasV1QueryVariables = {
  siteCode: string,
};

export type GetCachedSiteCamerasV1Query = {
  getCachedSiteCamerasV1?:  {
    __typename: "SiteCameras",
    siteCode: string,
    created: string,
    cameras:  Array< {
      __typename: "Camera",
      name: string,
      description?: string | null,
      systemIdentifier: string,
    } | null >,
  } | null,
};

export type GetDeviceLinkQueryVariables = {
  id: string,
};

export type GetDeviceLinkQuery = {
  getDeviceLink?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkV1QueryVariables = {
  id: string,
};

export type GetDeviceLinkV1Query = {
  getDeviceLinkV1?:  {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkEventQueryVariables = {
  id: string,
};

export type GetDeviceLinkEventQuery = {
  getDeviceLinkEvent?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetDeviceLinkEventV1QueryVariables = {
  id: string,
};

export type GetDeviceLinkEventV1Query = {
  getDeviceLinkEventV1?:  {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetEventQueryVariables = {
  id: string,
};

export type GetEventQuery = {
  getEvent?:  {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetEventV1QueryVariables = {
  id: string,
};

export type GetEventV1Query = {
  getEventV1?:  {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetEventLinksForSystemIdQueryVariables = {
  system_id: string,
};

export type GetEventLinksForSystemIdQuery = {
  getEventLinksForSystemId?:  {
    __typename: "EventLinks",
    event_links?:  Array< {
      __typename: "EventLink",
      device_link_id?: string | null,
      devices?:  {
        __typename: "EventLinkDevices",
        cameraName: string,
        cameraSystemIdentifier: string,
        accessControlDeviceName: string,
        accessControlDeviceParentDeviceID: number,
        accessControlDeviceChildDeviceID: number,
        accessControlDeviceSubchildDeviceID: number,
        accessControlDeviceType: string,
      } | null,
      events?: Array< string | null > | null,
      system?:  {
        __typename: "SystemForEventLink",
        auth_mode: AuthMode,
        system_id: string,
        secondary_system_id?: string | null,
        facman_sitename: string,
        srvr_alrm_srvc_path: string,
        srvr_alrm_srvc_port: string,
        srvr_cmd_srvc_path: string,
        srvr_cmd_srvc_port: string,
        srvr_cnf_srvc_path: string,
        srvr_cnf_srvc_port: string,
        srvr_vpc_endpoint: string,
      } | null,
    } | null > | null,
  } | null,
};

export type GetEventLinksForSystemIdV1QueryVariables = {
  system_id: string,
};

export type GetEventLinksForSystemIdV1Query = {
  getEventLinksForSystemIdV1?:  {
    __typename: "EventLinks",
    event_links?:  Array< {
      __typename: "EventLink",
      device_link_id?: string | null,
      devices?:  {
        __typename: "EventLinkDevices",
        cameraName: string,
        cameraSystemIdentifier: string,
        accessControlDeviceName: string,
        accessControlDeviceParentDeviceID: number,
        accessControlDeviceChildDeviceID: number,
        accessControlDeviceSubchildDeviceID: number,
        accessControlDeviceType: string,
      } | null,
      events?: Array< string | null > | null,
      system?:  {
        __typename: "SystemForEventLink",
        auth_mode: AuthMode,
        system_id: string,
        secondary_system_id?: string | null,
        facman_sitename: string,
        srvr_alrm_srvc_path: string,
        srvr_alrm_srvc_port: string,
        srvr_cmd_srvc_path: string,
        srvr_cmd_srvc_port: string,
        srvr_cnf_srvc_path: string,
        srvr_cnf_srvc_port: string,
        srvr_vpc_endpoint: string,
      } | null,
    } | null > | null,
  } | null,
};

export type GetSystemQueryVariables = {
  id: string,
};

export type GetSystemQuery = {
  getSystem?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetSystemV1QueryVariables = {
  id: string,
};

export type GetSystemV1Query = {
  getSystemV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetSystemForSegmentNameQueryVariables = {
  segmentName: string,
};

export type GetSystemForSegmentNameQuery = {
  getSystemForSegmentName?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type GetSystemForSegmentNameV1QueryVariables = {
  segmentName: string,
};

export type GetSystemForSegmentNameV1Query = {
  getSystemForSegmentNameV1?:  {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null,
};

export type ListAccessControlDevicesQueryVariables = {
  path: string,
};

export type ListAccessControlDevicesQuery = {
  listAccessControlDevices?:  Array< {
    __typename: "AccessControlDevice",
    Parent_DeviceID: number,
    Child_DeviceID: number,
    Subchild_DeviceID: number,
    Device_Type: string,
    DeviceName: string,
  } | null > | null,
};

export type ListAccessControlDevicesV1QueryVariables = {
  path: string,
};

export type ListAccessControlDevicesV1Query = {
  listAccessControlDevicesV1?:  Array< {
    __typename: "AccessControlDevice",
    Parent_DeviceID: number,
    Child_DeviceID: number,
    Subchild_DeviceID: number,
    Device_Type: string,
    DeviceName: string,
  } | null > | null,
};

export type ListAuditQueryVariables = {
  startDate: string,
  endDate: string,
  limit: number,
  offset: number,
};

export type ListAuditQuery = {
  listAudit?:  Array< {
    __typename: "Audit",
    event_id: string,
    table_name: string,
    action_tstamp_tx: string,
    action: string,
    row_data?: string | null,
    row_data_json?: string | null,
    changed_fields?: string | null,
    changed_fields_json?: string | null,
  } | null > | null,
};

export type ListAuditV1QueryVariables = {
  startDate: string,
  endDate: string,
  limit: number,
  offset: number,
};

export type ListAuditV1Query = {
  listAuditV1?:  Array< {
    __typename: "Audit",
    event_id: string,
    table_name: string,
    action_tstamp_tx: string,
    action: string,
    row_data?: string | null,
    row_data_json?: string | null,
    changed_fields?: string | null,
    changed_fields_json?: string | null,
  } | null > | null,
};

export type ListSiteCamerasQueryVariables = {
  siteCode: string,
  authMode: string,
  cmdSrvcEndpoint: string,
  cmdSrvcPath: string,
  cmdSrvcPort: string,
};

export type ListSiteCamerasQuery = {
  listSiteCameras?:  Array< {
    __typename: "Camera",
    name: string,
    description?: string | null,
    systemIdentifier: string,
  } | null > | null,
};

export type ListSiteCamerasV1QueryVariables = {
  siteCode: string,
  authMode: string,
  cmdSrvcEndpoint: string,
  cmdSrvcPath: string,
  cmdSrvcPort: string,
};

export type ListSiteCamerasV1Query = {
  listSiteCamerasV1?:  Array< {
    __typename: "Camera",
    name: string,
    description?: string | null,
    systemIdentifier: string,
  } | null > | null,
};

export type ListDeviceLinkEventsForDeviceLinkQueryVariables = {
  device_link_id: string,
};

export type ListDeviceLinkEventsForDeviceLinkQuery = {
  listDeviceLinkEventsForDeviceLink?:  Array< {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinkEventsForDeviceLinkV1QueryVariables = {
  device_link_id: string,
};

export type ListDeviceLinkEventsForDeviceLinkV1Query = {
  listDeviceLinkEventsForDeviceLinkV1?:  Array< {
    __typename: "DeviceLinkEvent",
    created: string,
    created_by: string,
    device_link_id: string,
    id: string,
    event_name: string,
    event_id: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinksForSiteQueryVariables = {
  segment_name: string,
};

export type ListDeviceLinksForSiteQuery = {
  listDeviceLinksForSite?:  Array< {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListDeviceLinksForSiteV1QueryVariables = {
  segment_name: string,
};

export type ListDeviceLinksForSiteV1Query = {
  listDeviceLinksForSiteV1?:  Array< {
    __typename: "DeviceLink",
    acd_device_name: string,
    acd_device_type: string,
    acd_parent_device_id: number,
    acd_child_device_id: number,
    acd_subchild_device_id: number,
    camera_name: string,
    camera_system_id: string,
    created: string,
    created_by: string,
    enabled: string,
    event_links?: Array< string | null > | null,
    id: string,
    system_id: string,
    system_name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListEventsQueryVariables = {
};

export type ListEventsQuery = {
  listEvents?:  Array< {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListEventsV1QueryVariables = {
};

export type ListEventsV1Query = {
  listEventsV1?:  Array< {
    __typename: "Event",
    created: string,
    created_by: string,
    device_type: string,
    id: string,
    name: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListUserPermissionQueryVariables = {
  path: string,
};

export type ListUserPermissionQuery = {
  listUserPermission?:  Array< {
    __typename: "Permission",
    HASPERMISSION: string,
  } | null > | null,
};

export type ListUserPermissionV1QueryVariables = {
  path: string,
};

export type ListUserPermissionV1Query = {
  listUserPermissionV1?:  Array< {
    __typename: "Permission",
    HASPERMISSION: string,
  } | null > | null,
};

export type ListUserSitesQueryVariables = {
  path: string,
};

export type ListUserSitesQuery = {
  listUserSites?:  Array< {
    __typename: "Site",
    SegmentName: string,
    SegmentLocation: string,
    SegmentSource: string,
    SiteCode: string,
  } | null > | null,
};

export type ListUserSitesV1QueryVariables = {
  path: string,
};

export type ListUserSitesV1Query = {
  listUserSitesV1?:  Array< {
    __typename: "Site",
    SegmentName: string,
    SegmentLocation: string,
    SegmentSource: string,
    SiteCode: string,
  } | null > | null,
};

export type ListSIGInfraUserSitesQueryVariables = {
  username: string,
};

export type ListSIGInfraUserSitesQuery = {
  listSIGInfraUserSites?:  {
    __typename: "SIGInfraSite",
    allSites?: boolean | null,
    sites?: Array< string | null > | null,
  } | null,
};

export type ListSIGInfraUserSitesV1QueryVariables = {
  username: string,
};

export type ListSIGInfraUserSitesV1Query = {
  listSIGInfraUserSitesV1?:  {
    __typename: "SIGInfraSite",
    allSites?: boolean | null,
    sites?: Array< string | null > | null,
  } | null,
};

export type ListSystemsQueryVariables = {
};

export type ListSystemsQuery = {
  listSystems?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSystemsV1QueryVariables = {
};

export type ListSystemsV1Query = {
  listSystemsV1?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSystemsForSiteQueryVariables = {
  segment_name: string,
};

export type ListSystemsForSiteQuery = {
  listSystemsForSite?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};

export type ListSystemsForSiteV1QueryVariables = {
  segment_name: string,
};

export type ListSystemsForSiteV1Query = {
  listSystemsForSiteV1?:  Array< {
    __typename: "System",
    auth_mode: AuthMode,
    created: string,
    created_by: string,
    enabled: string,
    id: string,
    name: string,
    secondary_system_id?: string | null,
    secondary_system_name?: string | null,
    segment_name: string,
    segment_location: string,
    segment_source: string,
    srvr_alrm_srvc_path: string,
    srvr_alrm_srvc_port: string,
    srvr_cmd_srvc_path: string,
    srvr_cmd_srvc_port: string,
    srvr_cnf_srvc_path: string,
    srvr_cnf_srvc_port: string,
    srvr_vpc_endpoint: string,
    updated: string,
    updated_by: string,
  } | null > | null,
};
