import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import * as APIt from '../API';
import { createUserAction as createUserActionMutationOp} from '../graphql/mutations';
import * as uuid from 'uuid';
import { UserActionNames } from '../constants/Constants';

export const ttl = (years: number): number => {
  const ttlDate = new Date();
  ttlDate.setFullYear(ttlDate.getFullYear()+years);
  const ttlEpochInt: number = Math.round(ttlDate.getTime()/1000);
  return ttlEpochInt;
}

export interface CreateUserActionInterface {
  username: string | undefined;
  actionName: UserActionNames;
  parameters?: string;
}
export const createUserAction = async (userAction: CreateUserActionInterface): Promise<APIt.UserAction | undefined | null> => {
  console.log(`createUserAction(): userAction is ${JSON.stringify(userAction)}`);

  try {
    const createUserActionMutationReponse = await API.graphql(graphqlOperation(createUserActionMutationOp,
      {
        input: {
          actionId: uuid.v4(),
          username: userAction.username,
          actionName: userAction.actionName,
          timestamp: new Date().getTime().toString(),
          parameters: userAction.parameters,
          ttl: ttl(2)
        }
      })) as GraphQLResult<APIt.CreateUserActionMutation>;
    console.log(`createUserAction(): createUserActionMutationReponse is ${JSON.stringify(createUserActionMutationReponse)}`);
    return createUserActionMutationReponse?.data?.createUserAction as APIt.UserAction;
  } catch(error) {
    console.error(`createUserAction(): error is ${JSON.stringify(error)}`);
    throw error;
  }
}
