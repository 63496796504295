import React, { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoVideocam } from 'react-icons/io5';
import { useState as useHookState } from '@hookstate/core';
import {
  Button,
  Container,
  Form,
  FormField,
  Grid,
  Select,
  SpaceBetween,
  Spinner,
  Toggle,
  Table,
  Popover,
  Box,
  CollectionPreferences,
  Pagination,
  TextFilter,
  Header,
  ContentLayout,
  Checkbox,
} from '@amzn/awsui-components-react';
import API, { GraphQLResult, graphqlOperation } from '@aws-amplify/api';
import { createUserAction } from 'src/utils/UserActionsUtils';
import { createDeviceLinkV1 as createDeviceLinkMutation, createDeviceLinkEventV1 } from "../../graphql/mutations";
import { CreatedDeviceLinkInterface } from './TablePanel';
import { CancelCreateDeviceLinkInterface } from './TablePanel';
import { RefreshDeviceOptionsCallBackInterface, RefreshCameraOptionsCallBackInterface, SelectOptionInterface } from './MainContents';
import * as APIt from "../../API";
import { forceAwakensBaseState } from 'src/stores/app';
import { listEventsV1 } from 'src/graphql/queries';
import { useBundle } from '@amzn/react-arb-tools';
import { UserActionNames } from 'src/constants/Constants';
import { FetchCameraImage } from 'src/utils/FetchCameraImage';
import { fetchPageSize } from 'src/utils/UserPreferences';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { TableEmptyState, TableNoMatchState, PaginationLabels } from './table-config';

export interface DeviceLinkCreatePanelPropsInterface {
  acpDeviceOptions: SelectOptionInterface[];
  acpDeviceOptionsLoading: boolean;
  cameraDeviceOptions: SelectOptionInterface[];
  cameraDeviceOptionsLoading: boolean;
  cancelCreateCallback: CancelCreateDeviceLinkInterface;
  deviceLinks: APIt.DeviceLink[];
  refreshDeviceOptions:RefreshDeviceOptionsCallBackInterface;
  refreshCameraOptions: RefreshCameraOptionsCallBackInterface;
  systemOptions: SelectOptionInterface[];
  saveCallback: CreatedDeviceLinkInterface;
}

export const DeviceLinkCreatePanel = (props: DeviceLinkCreatePanelPropsInterface) => {

  const [acdDeviceName, setAcdDeviceNameValue] = useState<string>();
  const [acdDeviceOptions, setACDDeviceOptions] = useState<SelectOptionInterface[]>(props.acpDeviceOptions);
  const [acdDeviceType, setAcdDeviceTypeValue] = useState<string>();
  const [acdParentDeviceId, setAcdParentDeviceIdValue] = useState<string>();
  const [acdChildDeviceId, setAcdChildDeviceIdValue] = useState<string>();
  const [acdSubchildDeviceId, setAcdSubchildDeviceIdValue] = useState<string>();
  const [selectedAcdDevice, setSelectedAcdDevice] = useState<SelectOptionInterface[]>();
  const [cameraDeviceOptions, setCameraDeviceOptions] = useState<SelectOptionInterface[]>(props.cameraDeviceOptions);
  const [enabledValue, setEnabledValue] = useState<string>('Y');
  const [filterLinkedCameras, setFilterLinkedCameras] = useState<boolean>(true);
  const [filterLinkedDevices, setFilterLinkedDevices] = useState<boolean>(true);
  const [filteringLinkedCameras, setFilteringLinkedCameras] = useState<boolean>(true);
  const [filteringLinkedDevices, setFilteringLinkedDevices] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<{description: string, label: string, value: string}[]>([]);
  const [systemIdValue, setSystemIdValue] = useState<string>();
  const [systemNameValue, setSystemNameValue] = useState<string>();
  const [devicePageSize, handleDevicePageSizeChange] = fetchPageSize('create_links_devices',20);
  const [cameraPageSize, handleCameraPageSizeChange] = fetchPageSize('create_links_camera',20);
  const [loadingDevices, setLoadingDevices] = useState<boolean>(false);
  const [loadingCameras , setLoadingCameras] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.DeviceLinks.Create');

  const forceAwakensState = useHookState(forceAwakensBaseState);

  useEffect(() => {
    if (props.systemOptions.length === 1) {
      setSystemIdValue(props.systemOptions[0].value);
      setSystemNameValue(props.systemOptions[0].label);
    }
  }, [])

  const { timeStamp, blobUrl, cameraImage, loading, fetchImage } = FetchCameraImage();

  const createDeviceLink = async () => {
    setSaving(true);
    for (let selectedCamera of selectedCameras) {
      try {
        const response = await API.graphql(graphqlOperation(createDeviceLinkMutation,
          {
            input:
              {
                system_id: systemIdValue,
                acd_device_name: acdDeviceName,
                acd_device_type: acdDeviceType,
                acd_parent_device_id: acdParentDeviceId,
                acd_child_device_id: acdChildDeviceId,
                acd_subchild_device_id: acdSubchildDeviceId,
                camera_name: selectedCamera.label,
                camera_system_id: selectedCamera.value,
                enabled: enabledValue,
                created_by: forceAwakensState.username.value
              }
          })) as GraphQLResult<APIt.CreateDeviceLinkV1Mutation>;
        if (response && response.data && response.data.createDeviceLinkV1) {
          createUserAction(
            {
              actionName: UserActionNames.CreateDeviceLink,
              username: forceAwakensState.username.value,
              parameters: JSON.stringify(
                {
                  createDeviceLink: response.data.createDeviceLinkV1,
                })
            });
          const createdDeviceLink = response.data.createDeviceLinkV1;
          if (acdDeviceType) await createDefaultDeviceLinkEvents(createdDeviceLink.id, acdDeviceType);
        }
      } catch (error) {
        setSaving(false);
        console.log(`createDeviceLink(): exception is ${JSON.stringify(error)}`);
        createUserAction(
          {
            actionName: UserActionNames.CreateDeviceLinkError,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                createDeviceLinkError: error,
              })
          });
        throw error;
      }
    }
    setSaving(false);
  }

  const cancelBtnHandler = () => {
    props.cancelCreateCallback();
  }

  const saveBtnHandler = async () => {
    await createDeviceLink();
    props.saveCallback();
  }

  const deviceLinkEnabledFieldOnChangeHandler = (detail: any) => {
    detail.checked ? setEnabledValue('Y') : setEnabledValue('N');
  };

  const deviceLinkSystemFieldOnChangeHandler = (detail: any) => {
    setSystemIdValue(detail.selectedOption.value);
    setSystemNameValue(detail.selectedOption.label);
  };

  const deviceLinkAccessControlDeviceFieldOnChangeHandler = (detail: any) => {
    const selectedDevice = detail.selectedItems[0];
    setAcdDeviceNameValue(selectedDevice['label']);
    setAcdDeviceTypeValue(selectedDevice['value'].split('-')[0]);
    setAcdParentDeviceIdValue(selectedDevice['value'].split('-')[1]);
    setAcdChildDeviceIdValue(selectedDevice['value'].split('-')[2]);
    setAcdSubchildDeviceIdValue(selectedDevice['value'].split('-')[3]);
    setSelectedAcdDevice([{label: selectedDevice['label'], value: selectedDevice['value']}]);
  };

  const getEventOptions = async (deviceType: string): Promise<SelectOptionInterface[]> => {
    let eventOptions: SelectOptionInterface[] = [];
    try {
      const response = await API.graphql(graphqlOperation(listEventsV1)) as GraphQLResult<APIt.ListEventsV1Query>;
      let events = response.data?.listEventsV1 as APIt.Event[];
      events = events.filter(el => {
        switch(el.name) {
          case 'Alarm Active':
            if (deviceType === 'alarm_panel_input' || deviceType === 'card_reader_aux_input_1' || deviceType === 'card_reader_aux_input_2')
              return true;
            else
              return false;
          default:
            if (deviceType !== 'alarm_panel_input' && deviceType !== 'card_reader_aux_input_1' && deviceType !== 'card_reader_aux_input_2')
              return true;
            else
              return false;
        }
      });
      eventOptions = events.map((el) => { return ({ label: el.name!, value: el.id! }); });
    } catch(error) {
      console.log(`getEventOptions(): error is ${JSON.stringify(error)}`);
    }
    return eventOptions;
  };

  const createDefaultDeviceLinkEvents = async (deviceLinkId: string, acdDeviceType: string) => {
    console.log(`createDefaultDeviceLinkEvents() deviceLinkId is ${deviceLinkId} acdDeviceType is ${acdDeviceType}`);
    const eventOptions = await getEventOptions(acdDeviceType);
    console.log(`createDefaultDeviceLinkEvents() eventOptions is ${JSON.stringify(eventOptions)}`);
    const events: SelectOptionInterface[] = [];
    switch (acdDeviceType) {
      case 'alarm_panel_input':
      case 'card_reader_aux_input_1':
      case 'card_reader_aux_input_2':
        events.push(...eventOptions.filter(eo => eo.label === 'Alarm Active'));
        break;
      case 'lidar':
        events.push(...eventOptions.filter(eo => eo.label.includes('Intrusion Alert:')));
        break;
      default:
        events.push(...eventOptions.filter(eo => eo.label === 'Access Denied'));
        events.push(...eventOptions.filter(eo => eo.label === 'Anti-Passback Violation'));
        events.push(...eventOptions.filter(eo => eo.label === 'Cabinet Tamper'));
        events.push(...eventOptions.filter(eo => eo.label === 'Door Forced Open'));
        events.push(...eventOptions.filter(eo => eo.label === 'Door Held Open'));
    }
    for (let event of events) {
      console.log(`createDefaultDeviceLinkEvents() event is ${JSON.stringify(event)}`);
      try {
        await API.graphql(graphqlOperation(createDeviceLinkEventV1,
          {
            input:
              {
                event_id: event.value,
                device_link_id: deviceLinkId,
                created_by: forceAwakensState.username.value
              }
          })) as GraphQLResult<APIt.CreateDeviceLinkEventV1Mutation>;
          createUserAction(
            {
              actionName: UserActionNames.CreateDeviceLinkEvent,
              username: forceAwakensState.username.value,
              parameters: JSON.stringify(
                {
                  event_id: event.value,
                  device_link_id: deviceLinkId,
                })
            });
      } catch(error) {
        console.log(`createDefaultDeviceLinkEvents(): error is ${JSON.stringify(error)}`);
        createUserAction(
          {
            actionName: UserActionNames.CreateDeviceLinkEvError,
            username: forceAwakensState.username.value,
            parameters: JSON.stringify(
              {
                event_id: event.value,
                device_link_id: deviceLinkId,
                createDeviceLinkEvErro: error,
              })
          });
      }
    }
  };

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const itemsCountCamera = (): number => {
    if (cameraDeviceOptions) return cameraDeviceOptions.length;
    return 0;
  };

  const itemsCountDevices = (): number => {
    if (acdDeviceOptions) return acdDeviceOptions.length;
    return 0;
  };

  useEffect(() => {
    setFilteringLinkedCameras(true);
    if (filterLinkedCameras) {
      const unlinkedCameraOptions = props.cameraDeviceOptions.filter(c => !props.deviceLinks.find(dl => dl.camera_name === c.label));
      setCameraDeviceOptions(unlinkedCameraOptions);
    } else {
      setCameraDeviceOptions(
        props.cameraDeviceOptions.map(c => {
          const deviceLinks = props.deviceLinks.filter(dl => dl.camera_name === c.label);
          return {
            description: deviceLinks.map(l => l.acd_device_name).join(', '),
            label: c.label,
            value: c.value,
          }
        })
      );
    }
    setFilteringLinkedCameras(false);
  }, [filterLinkedCameras]);

  const { items:cameraItems, actions:cameraActions, filteredItemsCount:filteredCameraItemsCount, collectionProps:cameraCollectionProps, filterProps: camerafilterProps, paginationProps: cameraPaginationProps } = useCollection(
    cameraDeviceOptions,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => cameraActions.setFiltering('')} />
      },
      pagination: { pageSize: cameraPageSize },
      sorting: {},
      selection: { trackBy: "id" }
    }
  );

  useEffect(() => {
    setFilteringLinkedDevices(true);
    if (filterLinkedDevices) {
      const unlinkedDeviceOptions = props.acpDeviceOptions.filter(c => !props.deviceLinks.find(dl => dl.acd_device_name === c.label));
      setACDDeviceOptions(unlinkedDeviceOptions);
    } else {
      setACDDeviceOptions(
        props.acpDeviceOptions.map(d => {
          const deviceLinks = props.deviceLinks.filter(dl => dl.acd_device_name === d.label);
          return {
            description: deviceLinks.map(l => l.camera_name).join(', '),
            label: d.label,
            value: d.value,
          }
        })
      );
    }
    setFilteringLinkedDevices(false);
  }, [filterLinkedDevices]);

  const { items: deviceItems, actions:deviceActions, filteredItemsCount:filteredDeviceItemsCount, collectionProps: deviceCollectionProps, filterProps:devicefilterProps, paginationProps:devicePaginationProps } = useCollection(
    acdDeviceOptions,
    {
      filtering: {
        empty: <TableEmptyState title={isBundleLoading ? 'Not Found' : bundle.getMessage('not-found')} />,
        noMatch: <TableNoMatchState onClearFilter={() => deviceActions.setFiltering('')} />
      },
      pagination: { pageSize: devicePageSize },
      sorting: {},
      selection: { trackBy: "id" }
    }
  );

  const refreshDevicesBtnClickHandler = async () => {
    setLoadingDevices(true);
    await props.refreshDeviceOptions();
    setLoadingDevices(false);
  
  };

  const refreshCameraBtnClickHandler = async () => {
    setLoadingCameras(true);
    await props.refreshCameraOptions();
    setLoadingCameras(false);
  };

  if (isBundleLoading) return <Spinner/>;

  return (
    <ContentLayout defaultPadding>
      <Form>
        <SpaceBetween size="s" direction="vertical">
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 4 }]}>
            <FormField label={bundle.getMessage('system')}>
              <Select
                onChange={({ detail }) => deviceLinkSystemFieldOnChangeHandler(detail)}
                options={props.systemOptions}
                selectedAriaLabel="Selected"
                selectedOption={{ label: systemNameValue, value: systemIdValue }}
              />
            </FormField>
          </Grid>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <Container>
              <Table
                filter={
                  <TextFilter
                    {...devicefilterProps}
                    filteringAriaLabel="Filter Device Names"
                    filteringPlaceholder={bundle.getMessage('find-device-name')}
                    countText={getFilterCounterText(filteredDeviceItemsCount === undefined ? 0 : filteredDeviceItemsCount)}
                  />
                }
                header={
                  <Header
                    counter={`(${itemsCountDevices().toString()})`}
                    actions={<Button onClick={refreshDevicesBtnClickHandler} iconName="refresh" />}
                  >
                    {bundle.getMessage('acd-header')}
                  </Header>
                }
                onSelectionChange={({ detail }) =>
                  deviceLinkAccessControlDeviceFieldOnChangeHandler(detail)
                }
                selectedItems={selectedAcdDevice}
                columnDefinitions={[
                  {
                    id: 'Access Device Names',
                    header: (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SpaceBetween size="xs" direction="horizontal">
                          {bundle.getMessage('access-control-device')}
                          <Toggle
                            checked={filterLinkedDevices}
                            onChange={({ detail }) => setFilterLinkedDevices(detail.checked)}
                          >
                            {bundle.getMessage('unlinked-devices-only')}
                          </Toggle>
                        </SpaceBetween>
                      </div>
                    ),
                    cell: item => item.label,
                    sortingField: "name",
                  }
                ]}
                items={deviceItems}
                selectionType="single"
                trackBy="value"
                loading={loadingDevices}
                loadingText={bundle.getMessage('loading-device-text')}
                pagination={
                  <Pagination
                    {...devicePaginationProps}
                    ariaLabels={PaginationLabels}
                  />
                }
                preferences={
                  <CollectionPreferences
                    onConfirm={({ detail }) => handleDevicePageSizeChange(detail.pageSize || devicePageSize)}
                    title={"User Preferences"}
                    confirmLabel={bundle.getMessage('confirm')}
                    cancelLabel={bundle.getMessage('cancel')}
                    preferences={{
                      pageSize: devicePageSize,
                    }}
                    pageSizePreference={{
                      title: bundle.getMessage('select-page-size'),
                      options: [
                        { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25, type: bundle.getMessage('device') }) },
                        { value: 50, label: bundle.formatMessage('number-of-devices', { deviceCount: 50, type: bundle.getMessage('device') }) },
                        { value: 100, label: bundle.formatMessage('number-of-devices', { deviceCount: 100, type: bundle.getMessage('device') }) },
                        { value: 150, label: bundle.formatMessage('number-of-devices', { deviceCount: 150, type: bundle.getMessage('device') }) },
                        { value: 250, label: bundle.formatMessage('number-of-devices', { deviceCount: 250, type: bundle.getMessage('device') }) },
                        { value: 500, label: bundle.formatMessage('number-of-devices', { deviceCount: 500, type: bundle.getMessage('device') }) }
                      ],
                    }}
                  />
                }
              />
            </Container>
            <Container>
              <Table
                filter={
                  <TextFilter
                    {...camerafilterProps}
                    filteringAriaLabel="Filter Camera Names"
                    filteringPlaceholder={bundle.getMessage('find-camera-name')}
                    countText={getFilterCounterText(filteredCameraItemsCount === undefined ? 0 : filteredCameraItemsCount)}
                  />
                }
                header={
                  <Header
                    counter={`(${itemsCountCamera().toString()})`}
                    actions={<Button onClick={refreshCameraBtnClickHandler} iconName="refresh" />}
                  >
                    Cameras
                  </Header>
                }
                selectionType="multi"
                columnDefinitions={[
                  {
                    id: 'cameraName',
                    header: (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SpaceBetween size="xs" direction="horizontal">
                          {bundle.getMessage('camera')}
                          <Toggle
                            checked={filterLinkedCameras}
                            onChange={({ detail }) => setFilterLinkedCameras(detail.checked)}
                          >
                            {bundle.getMessage('unlinked-cameras-only')}
                          </Toggle>
                        </SpaceBetween>
                      </div>
                    ),
                    cell: item => (
                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {item.label}
                          <Popover
                            triggerType="custom"
                            dismissButton={false}
                            size="large"
                            content={
                              <Box>
                                {loading ? (
                                  <Spinner />
                                ) : (
                                  cameraImage && (
                                    <>
                                      <img
                                        src={cameraImage}
                                        style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                      />
                                      {blobUrl && (
                                        <>
                                          <a href={blobUrl} target="_blank" rel="noopener noreferrer">{bundle.getMessage('image-full-size')}</a>
                                          <div>{timeStamp}</div>
                                        </>
                                      )}
                                    </>
                                  )
                                )}
                              </Box>
                            }
                          >
                            <IconContext.Provider value={{ style: { verticalAlign: 'middle', cursor: 'pointer', marginLeft: '8px', width: '16px', height: '16px' } }}>
                              <IoVideocam onClick={() => fetchImage(item.value)} />
                            </IconContext.Provider>
                          </Popover>
                        </div>
                      </div>
                    ),
                  },
                ]}
                onSelectionChange={({ detail }) => {
                  const selectedOptions = detail.selectedItems.map(v => ({ description: v.label!, label: v.label!, value: v.value! }));
                  setSelectedCameras(selectedOptions || []);
                }}
                items={cameraItems}
                loading={loadingCameras}
                loadingText={bundle.getMessage('loading-camera-text')}
                selectedItems={selectedCameras}
                trackBy="value"
                pagination={
                  <Pagination
                    {...cameraPaginationProps}
                    ariaLabels={PaginationLabels}
                  />
                }
                preferences={
                  <CollectionPreferences
                    onConfirm={({ detail }) => handleCameraPageSizeChange(detail.pageSize || cameraPageSize)}
                    title={"User Preferences"}
                    confirmLabel={"Confirm"}
                    cancelLabel={"Cancel"}
                    preferences={{
                      pageSize: cameraPageSize,
                    }}
                    pageSizePreference={{
                      title: "Select Page Size",
                      options: [
                        { value: 25, label: bundle.formatMessage('number-of-devices', { deviceCount: 25, type: bundle.getMessage('cameras') }) },
                        { value: 50, label: bundle.formatMessage('number-of-devices', { deviceCount: 50, type: bundle.getMessage('cameras') }) },
                        { value: 100, label: bundle.formatMessage('number-of-devices', { deviceCount: 100, type: bundle.getMessage('cameras') }) },
                        { value: 150, label: bundle.formatMessage('number-of-devices', { deviceCount: 150, type: bundle.getMessage('cameras') }) },
                        { value: 250, label: bundle.formatMessage('number-of-devices', { deviceCount: 250, type: bundle.getMessage('cameras') }) },
                        { value: 500, label: bundle.formatMessage('number-of-devices', { deviceCount: 500, type: bundle.getMessage('cameras') }) }
                      ],
                    }}
                  />
                }
              />
            </Container>
          </Grid>
          <FormField label={bundle.getMessage('enabled')}>
            <Checkbox
              onChange={({ detail }) => deviceLinkEnabledFieldOnChangeHandler(detail)}
              checked={enabledValue === 'Y'}
            >
              {bundle.getMessage('enabled')}
            </Checkbox>
          </FormField>
          <SpaceBetween size="xs" direction="horizontal">
            <Button onClick={cancelBtnHandler}>
              {bundle.getMessage('cancel')}
            </Button>
            <Button loading={saving} onClick={saveBtnHandler} variant="primary">
              {bundle.getMessage('save')}
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </Form>
    </ContentLayout>
  );
}